<form
  [formGroup]="form"
  (keydown.enter)="getHintEmail()"
  *transloco="let translated"
>
  <div class="form-group mb-4">
    <label class="form-label" for="username">
      Tên đăng nhập:
      <!-- Tên đăng nhập của bạn -->
      <span class="text-danger">(*)</span>
    </label>
    <input
      formControlName="username"
      zaaAutofocus
      type="text"
      id="username"
      class="form-control form-control-lg"
      placeholder="Tên tài khoản của bạn"
    />

    <div class="invalid-feedback d-block" *ngIf="this.displayErrorMessage">
      <!-- {{
          translated("auth.component.forgotPassword.inputUsername.usernameNotFoundError")
        }} -->
      {{ this.displayErrorMessage }}
    </div>
  </div>

  <button
    (click)="this.next()"
    type="button"
    class="btn-block btn btn-lg btn-primary waves-effect waves-themed mt-2"
    [disabled]="this.showSpinner"
    [ngClass]="{
      'cursor-default': this.showSpinner
    }"
  >
    @if (this.showSpinner) {
    <span
      class="spinner-border spinner-border-sm mr-2"
      role="status"
      aria-hidden="true"
    ></span>
    } Tiếp tục
  </button>

  <div class="d-flex align-items-center justify-content-center ">
    <a 
      (click)="this.goBack()"
      type="button"
      class="btn btn-lg waves-effect waves-themed text-primary"
    >
      <i class="fal fa-long-arrow-left mr-2"></i>
      Quay lại đăng nhập
    </a>
  </div>
</form>
