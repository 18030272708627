import { NgModule } from '@angular/core';
import { DecentralizeDirective } from './decentralization.directive';
import { DecentralizeRootDirective } from './decentralizationRoot.directive';
import {DecentralierService} from "@features/auth/core/auth-action/decentralization.services";
@NgModule({
  declarations: [DecentralizeDirective, DecentralizeRootDirective],
  providers: [DecentralierService],
  exports: [DecentralizeDirective, DecentralizeRootDirective]
})
export class DecentralizationModule { }
