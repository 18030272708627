<p class="text-left">
  1. Hệ thống đã gửi mã xác minh
  <strong> 6 chữ số </strong> đến địa chỉ email
  <strong>{{ this.formService.form.get("email")?.value }}</strong> hãy mở email
  và tìm mã xác minh. <br />
  2. Nếu chưa nhận được email trong vòng
  <strong>60 giây </strong> bạn có thể nhấn nút "Gửi lại mã" bên dưới để nhận 1
  mã xác minh mới. <br /><strong>
    Lưu ý:
    <span class="text-danger"
      >Mã này chỉ được dùng một lần và không cung cấp mã này cho bất kỳ ai để
      đản bảo an toàn cho tài khoản.</span
    ></strong
  >
</p>
<div *transloco="let translated">
  <div class="d-flex flex-column justify-content-center text-center">
    <div class="d-flex justify-content-center">
      <ng-otp-input
        [config]="this.OTP_CONF"
        (onInputChange)="inputOTPChange($event)"
      >
      </ng-otp-input>
    </div>

    @if (this.displayErrorMessage) {
    <div class="invalid-feedback fs-md d-block mt-1">
      {{ this.displayErrorMessage }}
    </div>
    }
    <div class="d-flex justify-content-center">
      <button
        class="btn border-top-0 border-left-0 border-right-0 border-bottom-0"
        style="cursor: pointer"
        [countdownButton]
        [countdownMessage]="
          translated('auth.component.accountfirstLogin.inputOtp.resendOTP')
        "
        (click)="this.onResend()"
      >
        {{ translated("auth.component.accountfirstLogin.inputOtp.resendOTP") }}
      </button>
    </div>
  </div>

  <button
    (click)="this.next()"
    type="button"
    [disabled]="this.OTP.length !== OTP_CONF.length || this.isInProcess"
    class="btn-block btn btn-lg btn-primary px-5 waves-effect waves-themed mt-3"
  >
    @if (this.isInProcess) {
    <span
      class="spinner-border spinner-border-sm mr-1"
      role="status"
      aria-hidden="true"
    ></span>
    }
    <span>Xác nhận </span>
  </button>
  <div class="d-flex align-items-center justify-content-center">
    <a
      (click)="this.goBack()"
      class="btn btn-lg waves-effect waves-themed text-primary"
    >
      <i class="fal fa-long-arrow-left mr-2"></i>
      <span> Quay lại </span>
    </a>
  </div>
</div>
