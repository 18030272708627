<div class="modal-header">
  <h5 class="modal-title">
    <h1 class="subheader-title">
      <i class="subheader-icon fal fa-window"></i> Cảnh báo cập nhật thông tin:
      <small>
        Vui lòng cập nhật thông tin với các yêu cầu dưới đây để tiếp tục sử dụng phần mềm
      </small>
    </h1>
  </h5>
</div>
<div class="modal-body">
  <table class="table table-bordered">
    <thead>
      <tr>
        <th>Thông tin</th>
        <th>Trạng thái</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let require of this.requirements">
        <td (click)="this.navigateTo(require.route)" style="cursor: pointer;">
          <strong>
            {{ require.desc }}
            <span class="text-danger" *ngIf="require.require">(*)</span>
            <i>
              <u>
                Cập nhật ngay
              </u>
            </i>
          </strong>
        </td>
        <td>{{ require.checked ? 'Đã cập nhật':'Chưa cập nhật' }}</td>
      </tr>
    </tbody>
  </table>
</div>