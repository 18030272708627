import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LINK_SUPPORT } from '@features/auth/const';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.css'],
})
export class AuthLayoutComponent implements OnInit {
  LINK_SUPPORT = LINK_SUPPORT;
  constructor() {}

  ngOnInit() {}
}
