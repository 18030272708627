import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputUsernameComponent } from '@features/auth/implements/forgot-password/components-steps/input-username/input-username.component';
import { InputEmailComponent } from '@features/auth/implements/forgot-password/components-steps/input-email/input-email.component';
import { NotifyAfterSendEmailComponent } from '@features/auth/implements/forgot-password/components-steps/notify/notify.component';
import { ForgotPasswordService } from '@features/auth/core/domain/forgot-password/forgot-password.service';
import { AuthLayoutComponent } from '@features/auth/shared/components/auth-layout/auth-layout.component';
import { TranslocoModule } from '@ngneat/transloco';
import { InputOtpComponent } from './components-steps/input-otp/input-otp.component';
import { InputPasswordComponent } from '../../shared/components/input-password/input-password.component';
import { ChangeDefaultPasswordComponent } from './components-steps/form-change-password/form-change-password.component';
import { ForgotPasswordFormService } from './forgot-password.form.service';
import { LINK_SUPPORT } from '@features/auth/const';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    InputUsernameComponent,
    InputEmailComponent,
    InputOtpComponent,
    ChangeDefaultPasswordComponent,
    NotifyAfterSendEmailComponent,
    AuthLayoutComponent,
    TranslocoModule,
    InputPasswordComponent,
  ],
  providers: [ForgotPasswordFormService],
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  // Form du lieu dung cho cac component ben trong
  formService = inject(ForgotPasswordFormService);
  form = inject(ForgotPasswordFormService).form;

  constructor() {}

  displayLogicError: string = '';
  readonly link = LINK_SUPPORT.FORGOT_PASSWORD

  ngOnInit() {}

  // Navigate:
  navigateToLogin = () => {
    this.formService.goToLogin();
  };
}
