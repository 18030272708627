import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { finalize } from 'rxjs';
import { InputUserNameHelper } from '@features/auth/shared/helpers/display-email-error.helper';
import { ForgotPasswordService } from '@features/auth/core/domain/forgot-password/forgot-password.service';
import { TranslocoModule } from '@ngneat/transloco';
import { ForgotPasswordFormService } from '../../forgot-password.form.service';
import { IFormNagivation } from '../../forgot-password.interface';
import { ZaaAutofocusDirective } from '@shared/directives/zaa-autofocus/zaa-autofocus.directive';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslocoModule,
    ZaaAutofocusDirective,
  ],
  selector: 'app-input-username',
  templateUrl: './input-username.component.html',
})
export class InputUsernameComponent implements OnInit, IFormNagivation {
  // Form service
  formService = inject(ForgotPasswordFormService);
  form = this.formService.form;

  // Api service
  public service = inject(ForgotPasswordService);

  public displayErrorMessage = '';
  public showSpinner = false;

  ngOnInit() {}

  goBack(): void {
    this.formService.goToLogin();
  }
  next(): void {
    this.getHintEmail();
  }

  getHintEmail() {
    this.displayErrorMessage = '';
    const userNameForm = this.form.get('username');

    // Validate
    if (userNameForm?.hasError('pattern')) {
      this.displayErrorMessage =
        InputUserNameHelper.displayError('wrongFormat');
      return;
    }
    if (userNameForm?.hasError('required')) {
      this.displayErrorMessage = InputUserNameHelper.displayError('empty');
      return;
    }

    if (userNameForm?.invalid) {
      return;
    }

    // Request
    this.showSpinner = true;
    this.form.disable();

    const { username } = this.form.getRawValue();

    this.service
      .getHintEmail(username ?? '')
      .pipe(
        finalize(() => {
          this.form.enable();
          this.showSpinner = false;
        })
      )
      .subscribe({
        next: (value) => {
          this.formService.emailHintDisplay = value;
          this.formService.goToInputEmail();
        },
        error: (err) => {
          console.error(err);
          this.displayErrorMessage = err.message;
        },
      });
  }
}
