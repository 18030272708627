import {Injectable, inject} from '@angular/core';
import {CanMatchFn, Route} from '@angular/router';
import {ILazyLoadModule} from './lazyload-module.interface';
import {DecentralizeModel} from '@features/auth/core/auth-action/decentralization.model';
import {DecentralierService} from '@features/auth/core/auth-action/decentralization.services';
import {AppRoutingService} from '@core/routing/app-routing.service';
import {AuthService} from '@features/auth/core/domain/login/auth-version-2.service';

/**
 * 1. Lớp guard này kiểm tra khả năng tải chậm một module và xử lý việc truy cập
 * vào module đó dựa trên điều kiện được cung cấp.
 *
 * 2. Nó triển khai giao diện CanLoad để kiểm tra khả năng tải module
 * và đảm bảo rằng module chỉ được tải nếu thỏa mãn điều kiện cần thiết.
 */
@Injectable()
export class LazyLoadModuleGuard {
  /**
   * @param router - AppRoutingService để xử lý chuyển hướng.
   * @param decentralizerService - DecentralierService để truy xuất đến các giá trị actionIds trong state.
   * @param authService
   */
  constructor(
    private readonly router: AppRoutingService,
    private readonly decentralizerService: DecentralierService,
    private readonly authService: AuthService,
  ) {
  }

  /**
   * Xác định xem module có thể tải hay không và xử lý truy cập vào module dựa trên điều kiện cung cấp.
   *
   * @param route - Route của module để lấy dữ liệu truyền trừ route.data -> Kiểm tra điều kiện tải module.
   * @returns Một Promise trả về giá trị boolean xác định liệu module có thể tải hay không.
   */
  canload = async (route: Route): Promise<boolean> => {
    const lazyloadAction = route.data as ILazyLoadModule | undefined;
    const isCanLoad = !lazyloadAction;

    if (isCanLoad) {
      return true;
    }

    const activeElements = this.decentralizerService.getActiveElement();

    const decentralizeModel =
      new DecentralizeModel(
        lazyloadAction?.key ?? '',
        activeElements, this.decentralizerService.conf.globalPolicies);
    /**
     * Logic: Module không được phép tải:
     * - Nếu phiên đăng nhập hợp lệ thì chuyển hướng sang trang lỗi 403
     * - Nếu phiên đăng nhập hết hạn thì chuyển hướng sang trang login.
     * */
    if (!decentralizeModel.isActive()) {
      if (this.authService.isLoggedIn()) {
        this.router.navigateToUnauthorizedPage();
      } else {
        this.router.navigateToNotFoundPage();
      }
      return false;
    }

    return true;
  }
}

/**
 * Hàm này trả về phương thức canload của lớp LazyLoadModuleGuard để sử dụng như một guard cho khả năng tải module.
 *
 * @param route - Route của module để lấy dữ liệu truyền trừ route.data -> Kiểm tra điều kiện tải module.
 * @returns Một Promise trả về giá trị boolean xác định liệu module có thể tải hay không.
 */
export const CanLazyloadModuleGuard: CanMatchFn = async (route: Route) => {
  return await inject(LazyLoadModuleGuard).canload(route);
}
