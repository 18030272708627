import { APIDataHandler } from '@core/models/data.handler';
import { IRequirement } from '../../../implements/require-update-info/interfaces/requirement.interface';
import { IRequirementModel } from '../../../implements/require-update-info/interfaces/requirement-viewmode.interface';

export class RequirementToUseAppModel
  implements APIDataHandler<IRequirementModel>
{
  public key!: string;
  public desc!: string;
  public require!: boolean;
  public checked!: boolean;
  public route!: string;
  public template!: string;

  keyRouteMaps = new Map<string, string>();

  allowRoute: string[] = ['/user/ho-so-ca-nhan'];

  constructor() {
    // Change follow api
    this.keyRouteMaps.set('UpdatePhone', 'user/ho-so-ca-nhan');
    this.keyRouteMaps.set('UpdateCoQuanCongTac', 'user/ho-so-ca-nhan');
  }

  mapArray(arrayRequire: Array<IRequirement>): Array<IRequirementModel> {
    return arrayRequire?.map((element) => this.mapDataFromAPI(element));
  }

  mapDataFromAPI(response: IRequirement): IRequirementModel {
    return {
      checked: response.checked,
      desc: response.mo_ta,
      key: response.key,
      require: response.require,
      route: this.keyRouteMaps.get(response.key) ?? '',
      template: response.template,
    };
  }
}
