import { Injectable, inject } from '@angular/core';
import { IProfile } from './profile.interface';
import { Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CoreAuthConfigService } from '@features/auth/core-auth-config.service';
import { URI_APIs } from '@features/auth/const';
import { ProfileModel } from './profile.model';

@Injectable()
export class ProfileService implements IProfile {
  private readonly httpClient = inject(HttpClient);
  private readonly conf = inject(CoreAuthConfigService);

  getAccountProfile(): Observable<ProfileModel> {
    return this.httpClient
      .get(this.conf.buildURL(URI_APIs.PROFILE_GET))
      .pipe(map((res: any) => new ProfileModel().mapFromAPI(res)));
  }

  updateAccountProfile(args: {
    avatar: string;
    ho_ten: string;
  }): Observable<any> {
    const url = this.conf.buildURL(URI_APIs.PROFILE_UPDATE);
    const requestBody = {
      ho_ten: args?.ho_ten,
      avatar: args?.avatar,
    };
    return this.httpClient.post(url, requestBody);
  }
}
