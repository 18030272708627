import {IRegister} from "@features/auth/core/domain/register/register.interface";
import {inject, Injectable} from "@angular/core";
import {catchError, Observable, throwError} from "rxjs";
import {IRegisterResponse} from "@features/auth/implements/register/interfaces/register-response.interface";
import {RegisterErrorModel} from "@features/auth/core/domain/register/register-error.model";
import {HttpClient} from "@angular/common/http";
import {CoreAuthConfigService} from "@features/auth/core-auth-config.service";
import {URI_APIs} from "@features/auth/const";

@Injectable()
export class RegisterService implements IRegister {
  private readonly httpClient = inject(HttpClient);
  private readonly conf = inject(CoreAuthConfigService);

  register(args: any): Observable<any> {
    const url = this.conf.buildURL(URI_APIs.REGISTER);
    const requestBody = {args}
    return this.httpClient
      .post<IRegisterResponse>(url, requestBody)
      .pipe(
        catchError((errorBase) => {
          return throwError(() => RegisterErrorModel.handle(errorBase));
        }),
      );
  }

}
