<div *transloco="let translated">
  <!-- #Body -->
  <h1 class="fw-700 fs-xxl text-left">
    <i class="fal fa-mail-bulk"></i>
    Nhập mã xác minh
  </h1>

  <!-- TODO: MOVE THIS CONTENT AS A VIEW COMPONENT -->
  <p class="text-left">
    1. Hệ thống đã gửi mã xác minh
    <strong> 6 chữ số </strong> đến địa chỉ email
    <strong> {{ this.otpFormService.getVerifyEmailAddress }} </strong> hãy mở email và tìm mã xác
    minh.
    <br />
    2. Nếu chưa nhận được email trong vòng
    <strong>60 giây </strong> bạn có thể nhấn nút "Gửi lại mã" bên dưới để nhận
    1 mã xác minh mới. <br /><strong>
      Lưu ý:
      <span class="text-danger"
        >Mã này chỉ được dùng một lần và không cung cấp mã này cho bất kỳ ai để
        đản bảo an toàn cho tài khoản.</span
      ></strong
    >
  </p>

  <div *transloco="let translated">
    <div class="modal-body">
      <div class="d-flex flex-column justify-content-center text-center">
        <div class="d-flex justify-content-center">
          <ng-otp-input
            [config]="this.INPUT_VERIFY_CODE_CONFIG"
            (onInputChange)="inputOTPChange($event)"
          >
          </ng-otp-input>
        </div>

        @if(this.displayErrorMessage) {
        <div class="invalid-feedback fs-md d-block mt-1">
          {{ this.displayErrorMessage }}
        </div>
        }

        <div class="d-flex justify-content-center">
          <button
            class="btn btn-outline-default border-top-0 border-left-0 border-right-0 border-bottom-0 cursor-pointer"
            [countdownButton]
            [countdownMessage]="
              translated('auth.component.accountfirstLogin.inputOtp.resendOTP')
            "
            (click)="this.onResend()"
          >
            {{
              translated("auth.component.accountfirstLogin.inputOtp.resendOTP")
            }}
          </button>
        </div>
      </div>
    </div>

    <button
      type="button"
      [disabled]="
        this.OTP.length !== INPUT_VERIFY_CODE_CONFIG.length || this.isInProcess
      "
      (click)="this.onSend()"
      class="btn-block btn btn-lg btn-primary px-5 waves-effect waves-themed mt-3"
    >
      @if (this.isInProcess) {
      <span
        class="spinner-border spinner-border-sm mr-1"
        role="status"
        aria-hidden="true"
      ></span>
      }
      <span>Xác nhận </span>
    </button>
    <div
      (click)="this.goBack()"
      class="d-flex align-items-center justify-content-center cursor-pointer"
    >
      <a class="fs-xl mt-4">
        <i class="fal fa-long-arrow-left mr-2"></i>
        <span> Quay lại </span>
      </a>
    </div>
  </div>
</div>
