import { ModuleWithProviders, NgModule } from '@angular/core';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthRequestInterceptor } from './core/interceptors/request.interceptor';
import {
  IApplicationConf,
  IDefaultAuthConf,
  ISocialNetworkAuth,
  provideApplicationConfig,
  provideDefaultAuth,
  provideGlobalPoliciesConfig,
  provideSocialNetworkAuth,
} from './core-auth.providers';
import { provideTranslocoScope } from '@ngneat/transloco';
import { AccessTokenGuard } from './core/guards/access-token.guard';
import { RouterModule } from '@angular/router';
import { authRoutes } from './core-auth.routing';
import { LazyLoadModuleGuard } from './core/guards/lazyload-module.guard';
import { RequirmentToUseApp } from './core/guards/requirement-to-use-app.guard';
import { RequireUpdateInfoGuard } from './core/guards/require-update-info.guard';
import { CoreAuthConfigService } from './core-auth-config.service';
import { SERVICE_IMPLEMENTS } from '@features/auth/core/domain/public-api';
import { DecentralizationModule } from '@features/auth/core/auth-action/decentralization.module';
import { ApiVersion2ResponseInterceptor } from './core/interceptors/api-version-2-response.interceptor';
import { ApiVersion1ResponseInterceptor } from './core/interceptors/api-version-1-response.interceptor';
import { API_VERSIONs, AuthVersion } from './const';
import {
  SSEResponseHandler,
  SSEAuthV2ResponseInterceptor,
  SSEAuthV1ResponseInterceptor,
} from './core/interceptors/sse-response.interceptor';

export interface ICoreAuthModuleConfig {
  socialNetworkAuth?: ISocialNetworkAuth;
  defaultAuth?: IDefaultAuthConf;
  application?: IApplicationConf;
  globalPolicies: object;
}

@NgModule({
  imports: [RouterModule.forChild(authRoutes), DecentralizationModule],
  providers: [
    AccessTokenGuard,
    LazyLoadModuleGuard,
    RequireUpdateInfoGuard,
    RequirmentToUseApp,

    CoreAuthConfigService,

    // Intercept for sse:
    SSEResponseHandler,
    SSEAuthV2ResponseInterceptor,
    SSEAuthV1ResponseInterceptor,

    // Services implements
    ...SERVICE_IMPLEMENTS,
  ],

  exports: [DecentralizationModule],
})
export class CoreAuthModule {
  static forRoot(
    configurations?: ICoreAuthModuleConfig
  ): ModuleWithProviders<CoreAuthModule> {
    return {
      ngModule: CoreAuthModule,
      providers: [
        provideSocialNetworkAuth(configurations?.socialNetworkAuth),
        provideDefaultAuth(configurations?.defaultAuth),
        provideApplicationConfig(configurations?.application),
        provideGlobalPoliciesConfig(configurations?.globalPolicies),
        provideTranslocoScope('auth'),
        // Interceptor http provider
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthRequestInterceptor,
          multi: true,
        },

        configurations?.application?.authVersion == AuthVersion.VERSION_1
          ? {
              provide: HTTP_INTERCEPTORS,
              useClass: ApiVersion1ResponseInterceptor,
              multi: true,
            }
          : {
              provide: HTTP_INTERCEPTORS,
              useClass: ApiVersion2ResponseInterceptor,
              multi: true,
            },
      ],
    };
  }
}
