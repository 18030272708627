import { Injectable } from "@angular/core";
import { StorageHelper } from "@features/auth/shared/helpers/storage.helper";
import { BehaviorSubject, Observable, Subject } from "rxjs";

export interface IAuthEvent {
  key: string, // STABLE_AUTH - NOT VALIDATE AND REALWORK
  value: any,
}

@Injectable()
export class AuthEventEmiter {
  private authEvent: Subject<any> = new BehaviorSubject<any>({});
  authEvent$: Observable<any> = this.authEvent.asObservable();

  constructor() {
    this.authEvent.next(StorageHelper.getCurrentAuthEvent())
  }

  push(event: IAuthEvent) {
    StorageHelper.setCurrentAuthEvent(event);
    this.authEvent.next(event);
  }
}