<app-zaa-breadcumb [breadcumbs]="['Trang chủ', 'Tài khoản']" [enableLanguage]="false"
  [activeIndex]="1"></app-zaa-breadcumb>

<div class="subheader">
  <h1 class="subheader-title">
    <i class="subheader-icon fal fa-users-cog"></i><span>Quản lý tài khoản</span>
  </h1>
</div>


<div id="panel-12" class="panel">
  <!-- <div class="panel-hdr">
      <h2>
          Pill <span class="fw-300"><i>vertical</i></span>
      </h2>
      <div class="panel-toolbar">
          <button class="btn btn-panel waves-effect waves-themed" data-action="panel-collapse"
              data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse"></button>
          <button class="btn btn-panel waves-effect waves-themed" data-action="panel-fullscreen"
              data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen"></button>
          <button class="btn btn-panel waves-effect waves-themed" data-action="panel-close"
              data-toggle="tooltip" data-offset="0,10" data-original-title="Close"></button>
      </div>
  </div> -->
  <div class="panel-container show">
    <div class="panel-content">
      <div class="row">
        <div class="col-auto border-faded border-left-0 border-top-0 border-bottom-0">
          <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <a class="nav-link" (click)="
                        onChangeTab(
                          this.ROUTING.ACTIVE_TABS.DISPLAY_TAB_ACCOUNT
                        )
                      " [ngClass]="{
                        active:
                          activeTab ===
                          this.ROUTING.ACTIVE_TABS.DISPLAY_TAB_ACCOUNT
                      }" data-toggle="pill" href="#{{
                        this.ROUTING.ACTIVE_TABS.DISPLAY_TAB_ACCOUNT
                      }}" role="tab">
              <i class="fal fa-home"></i>
              <span class="hidden-sm-down ml-1">Thông tin cá nhân</span>
            </a>
            <a class="nav-link" (click)="
                        onChangeTab(
                          this.ROUTING.ACTIVE_TABS.DISPLAY_TAB_RESET_PWD
                        )
                      " [ngClass]="{
                        active:
                          activeTab ===
                          this.ROUTING.ACTIVE_TABS.DISPLAY_TAB_RESET_PWD
                      }" data-toggle="pill" href="#{{
                        this.ROUTING.ACTIVE_TABS.DISPLAY_TAB_RESET_PWD
                      }}" role="tab">
              <i class="fal fa-user"></i>
              <span class="hidden-sm-down ml-1">Đổi mật khẩu</span>
            </a>
            <a class="nav-link disabled">
              <i class="fal fa-envelope"></i>
              <span class="hidden-sm-down ml-1">Cài đặt nhận thông báo</span>
            </a>
          </div>
        </div>
        <div class="col">
          <div class="tab-content" id="v-pills-tabContent">

            @switch (this.activeTab) { @case
              (this.ROUTING.ACTIVE_TABS.DISPLAY_TAB_ACCOUNT) {
              <div
                class="tab-pane fade show active"
                [id]="this.ROUTING.ACTIVE_TABS.DISPLAY_TAB_ACCOUNT"
                role="tabpanel"
                [attr.aria-labelledby]="
                  this.ROUTING.ACTIVE_TABS.DISPLAY_TAB_ACCOUNT
                "
              >
                <app-account-info />
              </div>
              } @case (this.ROUTING.ACTIVE_TABS.DISPLAY_TAB_RESET_PWD) {

              <div
                class="tab-pane fade show active"
                [id]="this.ROUTING.ACTIVE_TABS.DISPLAY_TAB_RESET_PWD"
                role="tabpanel"
                [attr.aria-labelledby]="
                  this.ROUTING.ACTIVE_TABS.DISPLAY_TAB_RESET_PWD
                "
              >
                <app-change-password></app-change-password>
              </div>
              }
              <!-- @case (this.ROUTING.ACTIVE_TABS.DISPLAY_TAB_ALERTS) {

                <div
                  class="tab-pane fade show active"
                  [id]="this.ROUTING.ACTIVE_TABS.DISPLAY_TAB_ALERTS"
                  role="tabpanel"
                  [attr.aria-labelledby]="
                    this.ROUTING.ACTIVE_TABS.DISPLAY_TAB_ALERTS
                  "
                >
                  <app-change-password></app-change-password>
                </div>
                } -->
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
