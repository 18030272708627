export interface IProfileResponse {
  ho_ten: string;
  email: string;
  avatar: string;
}

export class ProfileModel {
  ho_ten!: string;
  email!: string;
  avatar!: string;

  constructor(args?: any) {
    this.ho_ten = args?.fullName;
    this.email = args?.email;
    this.avatar = args?.avatar;
  }

  mapFromAPI(dto: IProfileResponse) {
    return new ProfileModel({
      fullName: dto.ho_ten,
      email: dto.email,
      avatar: dto.avatar,
    });
  }
}
