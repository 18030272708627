<form [formGroup]="form" (keydown.enter)="next()" *transloco="let translated">
  <div class="form-group mb-3">
    <label class="form-label" for="email"
      >Nhập địa chỉ email: <span class="text-danger"> (*)</span></label
    >
    <i>
      Địa chỉ email để xác minh
      <strong>{{ this.formService.emailHintDisplay }}</strong>
    </i>

    <input
      zaaAutofocus
      formControlName="email"
      type="text"
      id="email"
      class="form-control form-control-lg"
      placeholder="Ví dụ: abc@gmail.com"
    />
    <!-- TOTO: HANDLE ERROR CODE -->
    @if(this.displayErrorMessage) {
    <div class="invalid-feedback d-block">
      {{ this.displayErrorMessage }}
    </div>
    }
    
  </div>
  <button
    (click)="this.next()"
    type="button"
    class="btn-block btn btn-lg btn-primary px-5 waves-effect waves-themed mt-3"
  >
    @if (this.showSpinner) {
    <span
      class="spinner-border spinner-border-sm mr-1"
      role="status"
      aria-hidden="true"
    ></span>
    }
    <span> Gửi mã xác minh </span>
  </button>
  <div class="d-flex align-items-center justify-content-center">
    <a
      (click)="this.goBack()"
      class="btn btn-lg waves-effect waves-themed text-primary"
    >
      <i class="fal fa-long-arrow-left mr-2"></i>
      <span> Quay lại </span>
    </a>
  </div>
</form>
