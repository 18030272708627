import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { PUBLIC_ASSET_PATH } from '@features/auth/const';
import { VerifyAccountFirstLoginService } from '@features/auth/core/domain/account-first-login/verify-account-first-login.service';
import { AuthLayoutComponent } from '@features/auth/shared/components/auth-layout/auth-layout.component';
import { DeviceInfoHelper } from '@features/auth/shared/helpers/device-info.helper';
import { TranslocoModule } from '@ngneat/transloco';
import { finalize } from 'rxjs';
import { AccountFirstLoginFormOTPService } from '../../account-first-login-otp.service';

@Component({
  standalone: true,
  imports: [CommonModule, AuthLayoutComponent, TranslocoModule],
  selector: 'app-verify-device',
  templateUrl: './verify-device.component.html',
  styleUrls: ['./verify-device.component.css'],
})
export class VerifyDeviceComponent implements OnInit {
  service = inject(VerifyAccountFirstLoginService);
  otpFormService = inject(AccountFirstLoginFormOTPService);

  routing = inject(Router);

  constructor() {}

  ngOnInit() {}

  iconPath = PUBLIC_ASSET_PATH + '/img/auth/device-style-1.png';

  errorMessage = '';
  public displayErrorMessage = '';
  showSpinner: boolean = false;

  device = DeviceInfoHelper.getSimple();

  onVerify() {
    this.showSpinner = true;
    this.errorMessage = '';
    this.service
      .sendVerifyDeviceCode()
      .pipe(
        finalize(() => {
          this.showSpinner = false;
        })
      )
      .subscribe({
        next: (value) => {
          this.otpFormService.setSwitchViewVerifyCodeToPhone();
        },
        error: (err) => {
          console.error(err);
          this.displayErrorMessage = err.message;
        },
      });
  }

  goBack() {
    this.routing.navigate(['login']);
  }
}
