<app-auth-layout>
  <div class="d-flex align-items-center justify-content-center">
    <div class="w-25">
      <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <g data-name="Warning" id="Warning-2">
          <path
            class="cls-1"
            style="fill: #d8e1ef"
            d="M28.83,24.45l-12-18a1,1,0,0,0-1.66,0l-12,18a1,1,0,0,0,0,1A1,1,0,0,0,4,26H28a1,1,0,0,0,.88-.53A1,1,0,0,0,28.83,24.45Z"
          />
          <path
            class="cls-2"
            style="fill: #0593ff"
            d="M16,20a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0v6A1,1,0,0,1,16,20Z"
          />
          <path
            class="cls-2"
            style="fill: #0593ff"
            d="M16,23a1,1,0,0,1-1-1,1,1,0,0,1,2,0A1,1,0,0,1,16,23Z"
          />
        </g>
      </svg>
    </div>
  </div>
  <h1 class="mb-2 text-center">
    <div class="fw-700">LỖI KỸ THUẬT</div>
    Lỗi xảy ra khi hệ thống xác minh thông tin đăng nhập của bạn
  </h1>
  <p class="text-center">
    Vui lòng liên hệ với chúng tôi để được hỗ trợ, hoặc có thể thử lại sau vài
    phút. Trân trọng.
  </p>
  <button
    (click)="navigateToLogin()"
    type="submit"
    class="btn btn-primary btn-lg px-5 waves-effect waves-themed"
  >
    <span> Quay về đăng nhập </span>
  </button>
</app-auth-layout>
