import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthLayoutComponent } from '@features/auth/shared/components/auth-layout/auth-layout.component';
import { Router } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  standalone: true,
  imports: [CommonModule, AuthLayoutComponent, TranslocoModule],
  selector: 'app-error-view',
  templateUrl: './error-view.component.html',
  styleUrls: ['./error-view.component.css'],
})
export class ErrorViewComponent implements OnInit {
  private readonly router = inject(Router);
  constructor() {}

  ngOnInit() {}

  navigateToLogin() {
    this.router.navigate(['login']);
  }
}
