import { IVerifyAccountFirstLogin } from '@features/auth/core/domain/account-first-login/models/verify-account-first-login.interface';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap, throwError } from 'rxjs';
import { CoreAuthConfigService } from '@features/auth/core-auth-config.service';
import { HttpClient } from '@angular/common/http';
import { URI_APIs } from '@features/auth/const';
import { StorageHelper } from '@features/auth/shared/helpers/storage.helper';
import { RequireAuthInfoError } from '@features/auth/core/domain/require-update-info/require-auth-info-error';
import { VerifyAccountFirstLogin } from '@features/auth/core/domain/account-first-login/models/verify-account-first-login.model';
import { IRequireAuthInfoResponse } from './dto/require-auth.response';
import { ChangeDefaultPasswordModel } from './models/change-default-password.model';
import { VerifyOTPErrorModel } from './models/verify-otp-code.error';
import { VerifyDeviceModel } from './models/verify-device.model';

@Injectable()
export class VerifyAccountFirstLoginService
  implements IVerifyAccountFirstLogin
{
  private readonly httpClient = inject(HttpClient);
  private readonly conf = inject(CoreAuthConfigService);

  VerifyAccountFirstLogin = new VerifyAccountFirstLogin();

  getRequirementOfAccountWhenFirstLogin(): Observable<any> {
    return this.httpClient
      .get<IRequireAuthInfoResponse>(
        this.conf.buildURL(URI_APIs.VERIFY_FIRST_LOGIN_GET_REQUIRE)
      )
      .pipe(
        tap((response) => {
          this.VerifyAccountFirstLogin.setRequire(response);
        }),
        map(() => this.VerifyAccountFirstLogin.getFirstRequire()),
        tap((value) => {
          StorageHelper.storeAuthRequire(value);
        }),
        catchError((err) => throwError(() => RequireAuthInfoError.handle(err)))
      );
  }

  changeDefaultPassword(newPassword: string, options?: any): Observable<any> {
    const url = this.conf.buildURL(
      URI_APIs.VERIFY_FIRST_LOGIN_CHANGE_DEFAULT_PASSWORD
    );
    const requestBody = {
      new_password: newPassword,
    };
    return this.httpClient
      .post(url, requestBody)
      .pipe(
        catchError((err) =>
          throwError(() => ChangeDefaultPasswordModel.handleError(err))
        )
      );
  }

  getHintEmailAddress(username: string): Observable<any> {
    const url = this.conf.buildURL(
      URI_APIs.VERIFY_FIRST_LOGIN_GET_HINT_PHONE_NUMBER
    );
    const requestBody = {
      username: username,
    };
    return this.httpClient.post(url, requestBody);
  }

  // todo
  getHintPhoneNumber(username: string): Observable<any> {
    return of(true);
  }

  needToUpdateAuthInfoBeforeUseApp(): boolean {
    return !!StorageHelper.getAuthRequire();
  }

  verifyEmailAddress(email: string, options: any): Observable<any> {
    const url = this.conf.buildURL(URI_APIs.VERIFY_FIRST_LOGIN_EMAIL_ADDRESS);
    const requestBody = {
      email: email,
    };
    return this.httpClient
      .post(url, requestBody)
      .pipe(catchError((e) => throwError(() => VerifyOTPErrorModel.handle(e))));
  }

  verifyPhoneNumber(phoneNumber: string, options: any): Observable<any> {
    return of(true);
  }

  sendVerifyDeviceCode(): Observable<any> {
    return this.httpClient
      .post(this.conf.buildURL(URI_APIs.VERIFY_DEVICE), {})
      .pipe(
        catchError((e) => throwError(() => VerifyDeviceModel.handleError(e)))
      );
  }
}
