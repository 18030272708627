import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import {VerifyAccountFirstLoginService} from "@features/auth/core/domain/account-first-login/verify-account-first-login.service";

export const RequireUpdateAuthInfoGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(RequireUpdateInfoGuard).canActivate(route, state);
};

@Injectable()
export class RequireUpdateInfoGuard {
  service = inject(VerifyAccountFirstLoginService);
  routing = inject(Router);

  canActivate = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    if (this.service.needToUpdateAuthInfoBeforeUseApp()) {
      this.routing.navigate(['/cap-nhat-thong-tin']);
      return false;
    }
    return true;
  };
}

