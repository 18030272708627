import { Injectable, inject } from "@angular/core";
import { CoreAuthConfigService } from "@features/auth/core-auth-config.service";
import { AuthVersion1Service } from "./auth-version-1.service";
import { AuthService } from "./auth-version-2.service";

@Injectable()
export class LogoutService {
  conf = inject(CoreAuthConfigService);

  authV1 = inject(AuthVersion1Service);
  authV2 = inject(AuthService);

  logout() {
    return this.conf.isAuthV1() ? this.authV1.logout() : this.authV2.logout()
  }
}