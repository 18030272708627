import { ErrorBase } from '@core/models/error.model';

export class VerifyDeviceError extends Error {
  constructor(mgs: string) {
    super(mgs);
  }
}

export class VerifyDeviceModel {
  public static handleError(err: ErrorBase) {
    throw new VerifyDeviceError(err?.desc?.message);
  }
}
