import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { AppRoutingService } from '@core/routing/app-routing.service';
import { CommonModule } from '@angular/common';
import { CountdownButtonDirective } from '@features/auth/shared/directives/coutdown-and-disable.directive';
import { finalize, Subject } from 'rxjs';
import { VerifyAccountFirstLoginService } from '@features/auth/core/domain/account-first-login/verify-account-first-login.service';
import { Router } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { AccountFirstLoginFormOTPService } from '../../account-first-login-otp.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CountdownButtonDirective,
    TranslocoModule,
  ],
  selector: 'form-email',
  templateUrl: './form-email.component.html',
  styleUrls: ['./form-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit, OnDestroy {
  private readonly appRouting = inject(AppRoutingService);
  private readonly routing = inject(Router);
  private readonly service = inject(VerifyAccountFirstLoginService);
  private readonly otpFormService = inject(AccountFirstLoginFormOTPService);

  public emailForm!: FormControl;

  public showLoading: boolean = false;

  destroy$$ = new Subject<void>();

  displayErrorMessage: string = '';

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }
  ngOnInit() {
    this.emailForm = new FormControl('', [
      Validators.email,
      Validators.required,
    ]);
  }

  onVerifyEmail = (event: any) => {
    // The preventDefault() method cancels the event if it is cancelable, meaning that the default action that belongs to the event will not occur.
    event.preventDefault();

    if (this.emailForm.invalid) {
      return;
    }

    this.showLoading = true;
    this.emailForm.disable();

    this.service
      .verifyEmailAddress(this.emailForm.getRawValue(), {})
      .pipe(
        finalize(() => {
          this.showLoading = false;
          this.emailForm.enable();
        })
      )
      .subscribe({
        next: (value) => {
          // Navigate to input otp.
          this.otpFormService.setVerifyEmailAddress(this.emailForm.value);
          this.otpFormService.setSwitchViewVerifyCodeToEmail();
        },
        error: (err) => {
          console.error(err);
          this.displayErrorMessage = err.message;
        },
      });
  };

  goBack() {
    this.appRouting.navigateToLoginPage();
  }
}
