<!-- STATE 1 -->

<h1 class="fw-700 fs-xxl text-left">
  <i class="fal fa-key mr-2"></i>
  Thay đổi mật khẩu đăng nhập lần đầu
</h1>

<p class="text-left">
  Mật khẩu của bạn phải dài từ 8 đến 20 ký tự, phải chứa ít nhất 1 ký tự viết thường, 1 ký tự số và không có khoảng trắng.
</p>

<form class="mt-5" [formGroup]="changePasswordForm" *transloco="let translated">
  <!-- DISPLAY ERROR MESSAGE -->
  @if(this.displayErrorMessage) {
  <div class="alert alert-danger" role="alert">
    {{ this.displayErrorMessage }}
  </div>
  }
  <!-- DISPLAY ERROR MESSAGE -->

  <div class="form-group">
    <!-- Check khoong trung tai khoan -->
    <app-input-password
      [label]="'Mật khẩu mới'"
      [class]="'form-control form-control-lg'"
      [disabled]="this.inputNewPassword?.disabled ?? false"
      [setErrorMessage]="this.showPasswordNotMatchMessage"
      (changePassword)="this.inputNewPassword?.setValue($event)"
    >
    </app-input-password>

    <div class="mt-3"></div>

    <app-input-password
      [label]="'Xác nhận mật khẩu mới'"
      [class]="'form-control form-control-lg'"
      [disabled]="this.inputConfirmNewPassword?.disabled ?? false"
      [setErrorMessage]="this.showPasswordNotMatchMessage"
      (changePassword)="this.inputConfirmNewPassword?.setValue($event)"
    >
    </app-input-password>
  </div>
  <button
    type="button"
    (click)="this.onChangePassword()"
    [disabled]="!canEnableSendPasswordButton || this.waitingToVerify"
    class="btn-block btn btn-lg btn-primary px-5 waves-effect waves-themed mt-4"
  >
    @if (this.waitingToVerify) {
    <span
      class="spinner-border spinner-border-sm mr-1"
      role="status"
      aria-hidden="true"
    ></span>
    }
    <span>
      {{
        translated(
          "auth.component.accountfirstLogin.formChangePassword.onChangePassword"
        )
      }}
    </span>
  </button>
  <div class="d-flex align-items-center justify-content-center cursor-pointer">
    <a (click)="this.goBack()" class="fs-xl mt-4">
      <i class="fal fa-long-arrow-left mr-2"></i>
      <span>
        {{
          translated(
            "auth.component.accountfirstLogin.formChangePassword.goBack"
          )
        }}
      </span>
    </a>
  </div>
</form>
