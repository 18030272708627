<h1 class="fw-700 fs-xxl text-left">
  <i class="fal fa-phone-laptop mr-2"></i>
  Xác minh thiết bị
</h1>

<p class="text-left">
  Tài khoản của bạn đang hoạt động trên thiết bị chưa được xác minh. Vui lòng
  xác minh thiết bị này trước khi tiếp tục sử dụng ứng dụng
</p>
<div class="text-center">
  <img
    class="w-50"
    style="border-radius: 4px"
    [src]="this.iconPath"
    alt="device-icon"
  />
</div>
<div class="d-flex flex-column justify-content-center text-center">
  <div class="d-flex justify-content-center">
    @if (this.displayErrorMessage) {
    <div class="invalid-feedback fs-md d-block mt-1">
      {{ this.displayErrorMessage }}
    </div>
    }
  </div>
</div>

<button
  type="button"
  [disabled]="this.showSpinner"
  (click)="onVerify()"
  class="btn-block btn btn-lg btn-primary px-5 waves-effect waves-themed mt-4"
>
  @if (this.showSpinner) {
  <span
    class="spinner-border spinner-border-sm mr-1"
    role="status"
    aria-hidden="true"
  ></span>
  }
  <span> Gửi mã xác minh thiết bị </span>
</button>
<div class="d-flex align-items-center justify-content-center cursor-pointer">
  <a (click)="this.goBack()" class="fs-xl mt-4">
    <i class="fal fa-long-arrow-left mr-2"></i>
    <span> Quay về đăng nhập </span>
  </a>
</div>
