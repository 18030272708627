import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { VerifyAccountFirstLogin } from '@features/auth/core/domain/account-first-login/models/verify-account-first-login.model';
import { VerifyAccountFirstLoginService } from '@features/auth/core/domain/account-first-login/verify-account-first-login.service';
import { StorageHelper } from '@features/auth/shared/helpers/storage.helper';

@Injectable()
export class AccountFirstLoginFormOTPService {
  private readonly service = inject(VerifyAccountFirstLoginService);
  private readonly router = inject(Router);
  // From verify email.
  public typeVerify = {
    EMAIL: 'email',
    PHONE: 'sms', // sms
    DEVICE: 'device',
  };
  public currentStep = -1;
  public readonly CONTROLER_STEP = {
    CHANGE_PASSWORD: 0,
    VERIFY_EMAIL: 1,
    SEND_VERIFY_EMAIL_CODE_SUCCESS: 2,
    VERIFY_PHONE_NUMBER: 3,
    VERIFY_DEVICE: 4,
    FINISH: 5,
    VERIFY_OTP: 6,
  };

  public switchViewVerifyCode: string = '';

  private verifyEmailAddress: string = '';

  /**
   * If have a verify logic -> show finish form
   * else go to dashboard
   */

  setVerifyEmailAddress(email: string) {
    this.verifyEmailAddress = email;
  }

  get getVerifyEmailAddress() {
    return this.verifyEmailAddress;
  }

  isEmptyEmailAddress() {
    return this.verifyEmailAddress == '';
  }

  setSwitchViewVerifyCodeToEmail() {
    this.switchViewVerifyCode = this.typeVerify.EMAIL;
    this.currentStep = this.CONTROLER_STEP.VERIFY_OTP;
  }

  setSwitchViewVerifyCodeToPhone() {
    this.switchViewVerifyCode = this.typeVerify.PHONE;
    this.currentStep = this.CONTROLER_STEP.VERIFY_OTP;
  }

  setSwitchViewVerifyCodeToDevice() {
    this.switchViewVerifyCode = this.typeVerify.DEVICE;
  }

  get getHaveAVerifyLogic() {
    return StorageHelper.getAccountFirstLoginHaveAVerifyLogic();
  }
  setHaveAverifyLogic(bool: boolean) {
    StorageHelper.setAccountFirstLoginHaveAVerifyLogic(bool);
  }

  removeHaveAverifyLogic() {
    StorageHelper.removeAccountFirstLoginHaveAVerifyLogic();
  }

  nagivateToVerifyInfo() {
    // this.activeFormByKey();
    return this.router.navigate(['cap-nhat-thong-tin']);
  }

  activeFormByKey() {
    this.service
      .getRequirementOfAccountWhenFirstLogin()
      .pipe()
      .subscribe({
        next: (firstRequire) => {
          // Case first call have no require after login.
          if (!firstRequire) {
            if (this.getHaveAVerifyLogic) {
              this.currentStep = this.CONTROLER_STEP.FINISH;
              this.removeHaveAverifyLogic();
              return;
            }
            this.removeHaveAverifyLogic();
            window.location.href = StorageHelper.getCallBackURL();
            return;
          }

          this.setHaveAverifyLogic(true);

          switch (firstRequire.action_key) {
            case VerifyAccountFirstLogin.REQUIRE_TO_UPDATE_PASSWORD:
              this.currentStep = this.CONTROLER_STEP.CHANGE_PASSWORD;
              break;

            case VerifyAccountFirstLogin.REQUIRE_TO_UPDATE_EMAIL_KEY:
              this.currentStep = this.CONTROLER_STEP.VERIFY_EMAIL;
              break;

            case VerifyAccountFirstLogin.REQUIRE_TO_UPDATE_DEVICE_KEY:
              this.currentStep = this.CONTROLER_STEP.VERIFY_DEVICE;
              break;

            // TODO: IMPLEMENT LATTER
            case VerifyAccountFirstLogin.REQUIRE_TO_UPDATE_PHONE_KEY:
              this.currentStep = this.CONTROLER_STEP.VERIFY_PHONE_NUMBER;
              break;
          }
        },
        error: (err) => {
          console.log(err);
          this.router.navigate(['cap-nhat-thong-tin', 'loi-ngoai-le']);
        },
      });
  }
}
