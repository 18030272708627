import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AppRoutingService } from '@core/routing/app-routing.service';
import { finalize, take } from 'rxjs';
import { InputPasswordComponent } from '@features/auth/shared/components/input-password/input-password.component';
import { VerifyAccountFirstLoginService } from '@features/auth/core/domain/account-first-login/verify-account-first-login.service';
import { TranslocoModule } from '@ngneat/transloco';
import { PasswordStrengthHelper } from '@features/auth/shared/helpers/password-strength-validator.helper';
import { AccountFirstLoginFormOTPService } from '../../account-first-login-otp.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputPasswordComponent,
    TranslocoModule,
  ],
  selector: 'form-change-password',
  templateUrl: './form-change-password.component.html',
  styleUrls: ['./form-change-password.component.scss'],
})
export class ChangeDefaultPasswordComponent implements OnInit {
  private readonly appRoute = inject(AppRoutingService);
  private readonly service = inject(VerifyAccountFirstLoginService);
  private readonly formBuilder = inject(FormBuilder);
  otpFormService = inject(AccountFirstLoginFormOTPService);

  changePasswordForm!: FormGroup;

  // Ui display error;
  waitingToVerify: boolean = false;
  displayErrorMessage: string = '';

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group(
      {
        newPassword: [
          '',
          [
            Validators.required,
            PasswordStrengthHelper.createPasswordStrengthValidator(),
          ],
        ],
        confirmPassword: [
          '',
          [
            Validators.required,
            PasswordStrengthHelper.createPasswordStrengthValidator(),
          ],
        ],
      },
      {
        validators: [this.passwordsMatchValidator],
      }
    );
  }

  // Form logic
  // Kiểm tra pass và new pass bằng nhau
  private passwordsMatchValidator(
    group: FormGroup | any
  ): { [key: string]: boolean } | null {
    const password = group?.get('newPassword')?.value;
    const confirmPassword = group?.get('confirmPassword')?.value;
    return password === confirmPassword ? null : { passwordsNotMatch: true };
  }

  goBack() {
    this.appRoute.navigateToLoginPage();
  }

  get inputNewPassword() {
    return this.changePasswordForm.get('newPassword');
  }

  get inputConfirmNewPassword() {
    return this.changePasswordForm.get('confirmPassword');
  }

  get showPasswordNotMatchMessage() {
    return this.inputConfirmNewPassword?.value &&
      this.changePasswordForm.hasError('passwordsNotMatch')
      ? 'Mật khẩu mới và xác nhận mật khẩu không khớp'
      : '';
  }

  get canEnableSendPasswordButton() {
    return (
      !this.changePasswordForm.invalid &&
      !this.changePasswordForm.hasError('passwordsNotMatch') &&
      !this.changePasswordForm.hasError('mustMoreThen8Characters') &&
      !this.changePasswordForm.hasError('containNumberAndCharacters')
    );
  }

  // Api logic
  onChangePassword() {
    if (this.changePasswordForm.invalid) {
      return;
    }
    this.displayErrorMessage = '';

    const { newPassword } = this.changePasswordForm.getRawValue();
    this.waitingToVerify = true;

    this.service
      .changeDefaultPassword(newPassword)
      .pipe(
        take(1),
        finalize(() => {
          this.waitingToVerify = false;
        })
      )
      .subscribe({
        next: (value) => {
          this.otpFormService.activeFormByKey();
        },
        error: (error) => {
          console.error(error);
          this.displayErrorMessage = error.message;
        },
      });
  }
}
