import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { routes } from './account-first-login.routes';
import { AccountFirstLoginFormOTPService } from './account-first-login-otp.service';

@NgModule({
  declarations: [],
  providers: [AccountFirstLoginFormOTPService],
  imports: [ReactiveFormsModule, RouterModule.forChild(routes)],
})
export class VerifyAccountFirstLoginModule {}
