import { ErrorBase } from '@core/models/error.model';

export class ForgotPasswordError extends Error {
  constructor(mgs: string) {
    super(mgs);
  }
}

export class ForgotPasswordErrorModel {
  handle(error: ErrorBase) {
    throw new ForgotPasswordError(error.desc?.message);
  }
}
