<div class="col-xl-12 text-center" *transloco="let translated">
  <h2 class="fs-xxl fw-500 itext-center">
    <div
      class="d-flex align-items-center justify-content-center w-100 pt-3 pb-3 pr-2 pl-2"
    >
      <span class="icon-stack fa-3x">
        <i class="base-2 icon-stack-3x color-success-600"></i>
        <i class="fal fa-check icon-stack-1x text-white"></i>
      </span>
    </div>
    <small class="h4 fw-300 mt-3 mb-5 opacity-70">
      Chúc mừng bạn đã đỗi mật khẩu thành công, vui lòng đăng nhập lại để sử
      dụng ứng dụng
    </small>

    <button
      class="btn btn-primary btn-lg"
      type="button"
      (click)="this.next()"
    >
      <!-- Quay về trang đăng nhập -->
      {{ translated("auth.component.forgotPassword.notifi.goBack") }}
    </button>
  </h2>
</div>
