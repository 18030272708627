import { Directive, ElementRef, Inject, Input, OnInit, isDevMode } from "@angular/core";
import { DecentralizeModel } from "@features/auth/core/auth-action/decentralization.model";
import { DecentralierService } from "@features/auth/core/auth-action/decentralization.services";

/**
 * Directive này cho phép kiểm tra và ẩn các phần tử DOM dựa trên list khóa hành động được cung cấp của các quyền con của nó.
 * Nếu không có bất cứ thành phàn con nào có quyền hiện, phần tử DOM sẽ bị ẩn.
 */
@Directive({
  selector: '[authenActionRoot]',
})
export class DecentralizeRootDirective implements OnInit {

  /**
   * Khóa hành động cần kiểm tra ủy quyền cho dạng parent:
   * Sử dụng:
   * <div [authenAciton]="['path.of.key.to.action']"> Hello </div>
   */
  @Input('authenActionRoot') actionKeys: string[] = [];


  /**
   * @param elementRef - Tham chiếu tới phần tử DOM được liên kết với directive.
   * @param decentralizerService - Thể hiện của DecentralierService để truy xuất các yếu tố hoạt động hiện tại.
   */
  constructor(
    private readonly elementRef: ElementRef,
    private readonly decentralizerService: DecentralierService,
  ) {
  }

  /**
   * Lifecycle hook: ngOnInit.
   * Kiểm tra khóa hành động và ẩn phần tử DOM nếu không được ủy quyền.
   * Nếu không có khóa hành động nào được cung cấp, sẽ gây ra lỗi và ngừng việc khởi tạo.
   */

  ngOnInit() {

    let remove = true;
    this.actionKeys.map(ele => {
      if (ele.length === 0) {
        throw new Error(`When declare directive [authenAction] with empty key`);
      }
      const decentralizeModel =
        new DecentralizeModel(
          ele,
          this.getActiveActionsByUser(),
          this.decentralizerService.conf.globalPolicies
        );
      if (decentralizeModel.isActive()) {
        remove = false;
      }
    })
    if (remove) {
      this.elementRef.nativeElement.remove();
    }

  }


  /**
   * Lấy các actionIds của người dùng từ DecentralierService.
   *
   * @returns Mảng chứa các actionIds của người dùng.
   */
  private getActiveActionsByUser = () => {
    return this.decentralizerService.getActiveElement();
  }
}
