<ng-container *transloco="let translated">
  <div class="subheader">
    <h1 class="subheader-title">
      <i class="subheader-icon fal fa-address-card"></i>
      <span>
        {{ translated(this.TRANSLOCO_PATH + ".title") }}
      </span>
    </h1>
  </div>

  @if(this.listRequirement.length !== 0){
  <div class="alert border-danger alert-danger fade show" role="alert">
    <div class="d-flex align-items-start">
      <div class="alert-icon">
        <span class="icon-stack icon-stack-md">
          <i class="base-7 icon-stack-3x color-danger-900"></i>
          <i class="fal fa-user-cog icon-stack-1x text-white"></i>
        </span>
      </div>
      <div class="flex-1">
        <p class="h5 color-danger-900 mt-2">
          {{ translated(this.TRANSLOCO_PATH + ".notifyToUpdateInfo") }}
        </p>
        <ul class="pl-3">
          <ng-container *ngFor="let reqire of this.listRequirement">
            <li>
              {{ reqire.desc }}:( @if(reqire.checked) {
              {{ translated(this.TRANSLOCO_PATH + ".updatedStatus") }}
              } @else {
              {{ translated(this.TRANSLOCO_PATH + ".needToUpdateStatus") }}
              })
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
  }

  <div class="card">
    <div class="card-body">
      <!-- BEGIN - Body -->
      <div class="modal-body col-lg-9 col-xl-6">
        @if(this.showErrorWhenSave) {
          <div class="alert alert-danger" role="alert">
            <strong>
              Lỗi kỹ thuât: Không thể cập nhật hồ sơ cá nhân lúc này
            </strong>
          </div>
        }

        <form [formGroup]="changeInfomationForm">
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">
              <span>
                {{ translated(this.TRANSLOCO_PATH + ".formUpdate.ho_ten") }}
              </span>
              <span class="text-danger"> (*) </span>
            </label>
            <div class="col-8 col-md-4 col-sm-6 pr-0">
              <input
                type="text"
                [placeholder]="
                  translated(this.TRANSLOCO_PATH + '.formUpdate.ho_ten')
                "
                class="form-control"
                formControlName="ho_ten"
              />
              @if( changeInfomationForm.get('ho_va_ten')?.hasError('required') && this.showErrorWhenSave ) {
              <div class="invalid-feedback d-block">
                <span>
                  {{ translated(this.TRANSLOCO_VALIDATE + ".required") }}
                </span>
              </div>
              }
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label">
              <span> Email </span>
              <span class="text-danger"> (*) </span>
            </label>
            <div class="col-8 col-md-4 col-sm-6 pr-0">
              <input
                type="text"
                [placeholder]="'Email'"
                class="form-control"
                formControlName="email"
              />
              @if( changeInfomationForm.get('email')?.hasError('required') && this.showErrorWhenSave ) {
              <div class="invalid-feedback d-block">
                <span>
                  {{ translated(this.TRANSLOCO_VALIDATE + ".required") }}
                </span>
              </div>
              }
            </div>
          </div>

          <div class="row">
            <div class="col-sm-2"></div>
            <div class="col">
              <button
                type="button"
                (click)="onSubmit()"
                [disabled]="this.showLoadingWhenSave"
                class="btn btn-primary waves-effect waves-themed"
              >
                <span
                  *ngIf="this.showLoadingWhenSave"
                  class="spinner-border spinner-border-sm mr-1"
                  role="status"
                  aria-hidden="true"
                >
                </span>
                {{
                  translated(this.TRANSLOCO_PATH + ".formUpdate.onUpdateInfo")
                }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- END - Body -->
</ng-container>
