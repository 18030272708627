<h1 class="fw-700 fs-xxl text-left">
  <i class="fal fa-mail-bulk mr-2"></i>
  Xác minh địa chỉ Email
</h1>

<p class="text-left">
  Hãy nhập và thực hiện xác minh địa chỉ email để thuận tiện cho việc sử dụng và khôi phục tài khoản sau này. chúng tôi sẽ gửi mã xác minh đến địa chỉ email bạn đã nhập bên dưới. Nếu không xác minh địa chỉ của mình, bạn sẽ không thể truy cập tiếp.
</p>

<form class="mt-5" *transloco="let translated">
  <!-- DISPLAY ERROR MESSAGE -->
  @if(this.displayErrorMessage) {
  <div class="alert alert-danger" role="alert">
    {{ this.displayErrorMessage }}
  </div>
  }

  <div class="form-group">
    <label class="form-label" Ifor="input-email-address"
      >Nhập địa chỉ email: <span class="text-danger"> (*)</span></label
    >
    <input
      id="input-email-address"
      class="form-control form-control-lg"
      placeholder="Nhập địa chỉ email"
      type="text"
      [formControl]="emailForm"
      (keydown.enter)="onVerifyEmail($event)"
      [class.is-invalid]="
        emailForm.invalid && (emailForm.dirty || emailForm.touched)
      "
    />

    <div
      class="invalid-feedback"
      *ngIf="emailForm.invalid && (emailForm.dirty || emailForm.touched)"
    >
      {{
        translated(
          "auth.component.accountfirstLogin.verifyEmail.wrongEmailFormatErrorMessage"
        )
      }}
      {{ "abc@gmail.com" }}
    </div>
  </div>
</form>

<button
  type="button"
  [disabled]="this.emailForm.invalid || this.showLoading"
  (click)="onVerifyEmail($event)"
  class="btn-block btn btn-lg btn-primary px-5 waves-effect waves-themed mt-4"
>
  @if (this.showLoading) {
  <span
    class="spinner-border spinner-border-sm mr-1"
    role="status"
    aria-hidden="true"
  ></span>
  }
  <span> Gửi mã xác minh </span>
</button>
<div class="d-flex align-items-center justify-content-center cursor-pointer">
  <a (click)="this.goBack()" class="fs-xl mt-4">
    <i class="fal fa-long-arrow-left mr-2"></i>
    <span> Quay về đăng nhập </span>
  </a>
</div>
