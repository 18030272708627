import { Injectable, inject, isDevMode } from "@angular/core";
import { IResponseErrorHandler } from "./response-error-handler";
import { CoreAuthConfigService } from "@features/auth/core-auth-config.service";
import { HttpStatusCode } from "@angular/common/http";
import { ToastrService } from "@shared/components/lib-ngx/ngx-toastr/toastr/toastr.service";
import { Router } from "@angular/router";

/**
 * NOTE: Response cua bo phan quyen khong khop voi response sse ne khong the map du lieu dung de dieu huong.
 * Chi co the canh bao
 */
@Injectable()
export class SSEAuthV1ResponseInterceptor implements IResponseErrorHandler {
  toastr = inject(ToastrService);

  handleHttpError(error: { status: number, message: string }) {
    switch (error.status) {
      /**
       * Case 401 : HttpStatusCode.Unauthorized - Chưa đăng nhập, hoaac chua khai bao api
       * Nếu chưa đăng nhập sẽ chuyển trang sang trang đăng nhập.
       */
      case HttpStatusCode.Unauthorized:
        this.toastr.warning('Tính năng này chưa được cập nhật hoặc phiên đăng nhập hết hạn, vui lòng thử lại sau!', 'Cảnh báo')
        return false;

      /**
       * Case 403 : HttpStatusCode.Forbidden - Không được cấp quyền truy cập
       */
      case HttpStatusCode.Forbidden:
        this.toastr.warning('Bạn không có quyền truy cập tính năng này!', 'Cảnh báo')
        return false

      default:
        break;
    }
    return true;
  }

}

@Injectable()
export class SSEAuthV2ResponseInterceptor implements IResponseErrorHandler {
  toastr = inject(ToastrService);
  router = inject(Router);

  handleHttpError(error: { status: number, message: string }) {
    switch (error.status) {
      case HttpStatusCode.Unauthorized:
        this.toastr.warning('Tính năng này chưa được cập nhật hoặc phiên đăng nhập hết hạn, vui lòng thử lại sau!', 'Cảnh báo')
        return false

      case HttpStatusCode.Forbidden:
        this.toastr.warning('Tính năng này đã bị khóa đối với tài khoản của bạn, vui lòng đăng nhập lại hoặc liên hệ với chúng tôi để hỗ trợ', 'Cảnh báo')
        return false;
      default:
        break;
    }

    return true;
  }
}

@Injectable()
export class SSEResponseHandler implements IResponseErrorHandler {
  private conf = inject(CoreAuthConfigService);
  private sseInterceptV1 = inject(SSEAuthV1ResponseInterceptor)
  private sseInterceptV2 = inject(SSEAuthV2ResponseInterceptor)

  handleHttpError(error: any) {
    if (this.conf.isAuthV1()) {
      return this.sseInterceptV1.handleHttpError(error)
    }

    return this.sseInterceptV2.handleHttpError(error);
  }
}