export class InputUserNameHelper {
  // Todo Change Language
  public static displayError(
    code:
      | 'wrongFormat'
      | 'internalServerError'
      | 'empty'
      | 'emailNotFound'
      | 'usernameNotFound'
  ) {
    switch (code) {
      case 'wrongFormat':
        return `Địa chỉ email sai định dạng, vui lòng kiểm tra lại`;

      case 'empty':
        return `Email đăng nhập không được để trống, vui lòng kiểm tra lại`;

      case 'emailNotFound':
        return `Email này không tồn tại trong hệ thống, vui lòng kiểm tra lại`;

      case 'usernameNotFound':
        return `Tên đăng nhập này không tồn tại trong hệ thống, vui lòng kiểm tra lại`;

      default:
        break;
    }
    return '';
  }
}
