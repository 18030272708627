import { Routes } from '@angular/router';
import { FormCapNhatThongTin } from './require-update-info.component';
import { ErrorViewComponent } from './components/error-view/error-view.component';

export const routes: Routes = [
  {
    // Form handle navigation to specific bussiness logic
    path: '',
    component: FormCapNhatThongTin,
  },
  {
    path: 'loi-ngoai-le', // Error when get fisrt info
    component: ErrorViewComponent,
  },
];
