import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { StorageHelper } from '@features/auth/shared/helpers/storage.helper';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  selector: 'app-finished-form',
  templateUrl: './finished-form.component.html',
  styleUrls: ['./finished-form.component.css'],
})
export class FinishedFormComponent {
  router = inject(Router);
  onNavigateToHome() {
    window.location.href = StorageHelper.getCallBackURL();
  }

  navigateToLogin() {
    this.router.navigateByUrl('login');
  }
}
