import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { NgbModal } from '@shared/components/lib-ng/lib-ng-bootstrap/modal/modal';
import { DisplayRequireComponent } from '../../implements/require-update-info/display-require/display-require.component';
import { IRequirementModel } from '../../implements/require-update-info/interfaces/requirement-viewmode.interface';
import { RequirementToUseAppModel } from '../domain/require-update-info/requirement-to-use-app.model';
import {RequireUpdateInfoService} from "@features/auth/core/domain/require-update-info/require-update-info.service";

@Injectable()
export class RequirmentToUseApp {
  public modal = inject(NgbModal);
  public service = inject(RequireUpdateInfoService);
  public model = new RequirementToUseAppModel();

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return true;
    // if (!ENABLE_REQUIRE_UPDATE_INFO) {
    //   return true;
    // }

    // if (this.model.allowRoute.includes(state.url)) {
    //   return of(true);
    // }

    // // Lỗi thì cho qua luôn (nếu khôgn quan trọng)
    // if (this.service.getError()) {
    //   return of(true);
    // }

    // return this.service.getRequirement().pipe(
    //   map((requirements) => {
    //     this.service.setError(false);
    //     const required = _.filter(
    //       requirements,
    //       (value) => value.require && !value.checked,
    //     );
    //     if (required.length === 0) {
    //       return true;
    //     }

    //     this.showNotifyMessage(requirements);
    //     return false;
    //   }),
    //   catchError((err) => {
    //     this.service.setError(true);
    //     console.error(err);
    //     return of(true);
    //   }),
    // );
  }

  showNotifyMessage(requires: Array<IRequirementModel>) {
    const modal = this.modal.open(DisplayRequireComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
    });

    modal.componentInstance.requirements = requires;
  }
}

export const RequirmentToUseAppGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(RequirmentToUseApp).canActivate(route, state);
};
