import { ErrorBase } from '@core/models/error.model';

export class ChangeDefaultPasswordError extends Error {
  constructor(mgs: string) {
    super(mgs);
  }
}
export class ChangeDefaultPasswordModel {
  public static handleError(err: ErrorBase) {
    throw new ChangeDefaultPasswordError(err.desc?.message);
  }
}
