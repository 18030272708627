import { Inject, Injectable, inject } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../domain/login/auth-version-2.service';
import { CoreAuthConfigService } from '@features/auth/core-auth-config.service';
import { StorageHelper } from '@features/auth/shared/helpers/storage.helper';

@Injectable()
export class AuthRequestInterceptor implements HttpInterceptor {
  private authService = inject(AuthService);
  private conf = inject(CoreAuthConfigService);

  private appKey: string = '';
  private tokenType: string = '';

  constructor() {
    this.appKey = this.conf.applicationConfig.appKey ?? '';
    this.tokenType = this.conf.applicationConfig.tokenType ?? '';
  }

  /**
   * Phương thức intercept được gọi mỗi khi có một HTTP request sắp được gửi đi.
   * Phương thức này sẽ thêm các header cần thiết vào request và trả về request đã được chỉnh sửa.
   *
   * @param request HttpRequest<any> - Đối tượng request ban đầu.
   * @param next HttpHandler - Bộ xử lý HTTP tiếp theo trong chuỗi xử lý.
   * @returns Observable<HttpEvent<any>> - Một Observable chứa các sự kiện HTTP.
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const appRequest = request.clone({
      setHeaders: this.getApplicationHeaders(),
    });

    return next.handle(appRequest);
  }

  /**
   * Phương thức private getApplicationHeaders được sử dụng để lấy các thông tin header
   * cần thiết từ localStorage và environment để đưa vào request.
   *
   * @returns {Object} - Một đối tượng chứa các thông tin header.
   * @private
   */
  private getApplicationHeaders = (): any => {
    this.appKey =
      StorageHelper.getAppKey() != null
        ? StorageHelper.getAppKey()?.toString() ?? ''
        : this.appKey.toString();
        
    return {
      Authorization: this.authService.buildTokenByJWTPrefix(this.tokenType),
      ['app-key']: this.appKey.toString(),
    };
  };
}
