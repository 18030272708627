import { Injectable, inject, isDevMode } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { EMPTY, Observable, TimeoutError, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import _ from 'lodash';
import { NgbModal } from '@shared/components/lib-ng/lib-ng-bootstrap/modal/modal';
import { ErrorBase } from '@core/models/error.model';
import { Router } from '@angular/router';
import { CoreAuthConfigService } from '@features/auth/core-auth-config.service';
import { IResponseErrorHandler } from './response-error-handler';
import { ToastrService } from '@shared/components/lib-ngx/ngx-toastr/toastr/toastr.service';

//DOC: https://angular.io/guide/http-intercept-requests-and-responses
@Injectable()
export class ApiVersion1ResponseInterceptor
  implements HttpInterceptor, IResponseErrorHandler
{
  router = inject(Router);
  conf = inject(CoreAuthConfigService);
  toastr = inject(ToastrService);

  constructor(private readonly modalService: NgbModal) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    /**
     * PIPE: [---> handleGlobalError ---> mappingHttpErrorResponseToErrorBase ----> ]
     */
    const API_VERSION_HEADER_KEY = 'X-Api-Version';
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.handleHttpError(error);
        const apiVersion = error.headers.get(API_VERSION_HEADER_KEY);
        return throwError(() => new ErrorBase(error, apiVersion));
      })
    );
  }

  handleHttpError = (error: HttpErrorResponse) => {
    switch (error.status) {
      /**
       * Case 401 : HttpStatusCode.Unauthorized - Chưa đăng nhập:
       * Nếu chưa đăng nhập sẽ chuyển trang sang trang đăng nhập.
       */
      case HttpStatusCode.Unauthorized:
        // TODO: Every when use modal need to dismiss them when unauthorize:
        // WARNGING: How to dismiss all type of model ??
        const noToken = { message: 'NO_TOKEN' };
        const notJwtToken = { message: 'NOT_JWT_TOKEN' };
        if (
          _.isEqual(error.error, noToken) ||
          _.isEqual(error.error, notJwtToken)
        ) {
          this.modalService.dismissAll();

          // UPGRADE: if have config useCustomLoginPageURL: > navigate to this page
          if (this.conf?.applicationConfig?.useCustomLoginPageURL) {
            window.location.href =
              this.conf?.applicationConfig?.useCustomLoginPageURL;
            return EMPTY;
          }

          // By default navigate to login
          this.router.navigate(['login'], { queryParams: { error: 400 } });

          return EMPTY;
        }

        const noAPI = { code: -1, message: 'NO_API' };
        if (_.isEqual(error?.error, noAPI)) {
          this.toastr.warning(
            'Tính năng này chưa được cập nhật, vui lòng thử lại sau!',
            'Cảnh báo'
          );
          return error;
        }

        return EMPTY;

      /**
       * Case 403 : HttpStatusCode.Forbidden - Không được cấp quyền truy cập
       * - Chuyển trang 403 nếu không có quyền
       * - Chuyển trang đăng nhập nếu chưa đăng nhập
       */
      case HttpStatusCode.Forbidden:
        const haveNoAuthResponse = { message: 'NOT_ALLOW' };
        if (_.isEqual(error?.error, haveNoAuthResponse)) {
          this.toastr.warning(
            'Bạn không có quyền truy cập tính năng này!',
            'Cảnh báo'
          );
        }
        return EMPTY;

      default:
        break;
    }

    return error;
  };
}
