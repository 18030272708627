import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NgbModalModule } from '@shared/components/lib-ng/lib-ng-bootstrap/modal/modal.module';

import { AccountPageComponent } from './account.component';

const pageBienTapChuyenMonRoutes: Routes = [
  {
    path: '',
    component: AccountPageComponent,
    children: [
      {
        path: 'thong-tin',
        loadComponent: () => import('./account-info/account-info.component').then(m => m.AccountInfoComponent),
      },
      {
        path: 'doi-mat-khau',
        loadComponent: () => import('./change-password/change-password.component').then(m => m.ChangePasswordComponent),
      },
      {
        path: '',
        redirectTo: 'thong-tin',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [NgbModalModule, RouterModule.forChild(pageBienTapChuyenMonRoutes)],
  exports: [RouterModule],
})
export class PageAccountSettingRoutingModule {}
