import { CommonModule } from '@angular/common';
import { Component, Input, inject, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { IRequirementModel } from '../interfaces/requirement-viewmode.interface';
import { NgbActiveModal } from '@shared/components/lib-ng/lib-ng-bootstrap/modal/modal-ref';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-display-require',
  templateUrl: './display-require.component.html',
  styleUrls: ['./display-require.component.css'],
})
export class DisplayRequireComponent {
  @Input() requirements: Array<IRequirementModel> = [];

  public route = inject(Router);
  public activeModal = inject(NgbActiveModal);

  navigateTo(route: string) {
    this.route.navigate([route]);
    isDevMode() &&
      console.log(`DisplayRequireComponent: Just navigate to route ${route}`);
    this.activeModal.close();
  }
}
