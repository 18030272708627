import {Directive, ElementRef, Inject, Input, OnInit, inject, isDevMode} from "@angular/core";
import {DecentralizeModel} from "./decentralization.model";
import {DecentralierService} from "./decentralization.services";
import {CoreAuthConfigService} from "@features/auth/core-auth-config.service";

@Directive({
  selector: '[authenAction]',
})
export class DecentralizeDirective implements OnInit {
  private readonly elementRef = inject(ElementRef);
  @Input('authenAction') actionKey: string = '';

  constructor(
    private readonly service: DecentralierService,
  ) {
  }

  ngOnInit() {
    if (this.actionKey.length === 0) {
      throw new Error(`When declare directive [authenAction] with empty key`);
    }

    const decentralizeModel =
      new DecentralizeModel(
        this.actionKey,
        this.getActiveActionsByUser(),
        this.service.conf.globalPolicies
    );

    if (!decentralizeModel.isActive()) {
      this.elementRef.nativeElement.remove();
    }
  }

  private getActiveActionsByUser = () => {
    return this.service.getActiveElement();
  }
}
