import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgOtpInputComponent } from '@features/auth/shared/components/ng-otp-input/ng-otp-input.component';
import { CountdownButtonDirective } from '@features/auth/shared/directives/coutdown-and-disable.directive';

import { AuthLayoutComponent } from '@features/auth/shared/components/auth-layout/auth-layout.component';
import { TranslocoModule } from '@ngneat/transloco';
import { ForgotPasswordFormService } from '../../forgot-password.form.service';
import { INPUT_VERIFY_CODE_CONFIG } from './config';
import { IFormNagivation } from '../../forgot-password.interface';
import { ForgotPasswordService } from '@features/auth/core/domain/forgot-password/forgot-password.service';
import { finalize } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    NgOtpInputComponent,
    CountdownButtonDirective,
    AuthLayoutComponent,
    TranslocoModule,
  ],
  selector: 'app-forgot-password-input-otp',
  templateUrl: './input-otp.component.html',
  styleUrls: ['./input-otp.component.css'],
})
export class InputOtpComponent implements OnInit, IFormNagivation {
  // Form service
  formService = inject(ForgotPasswordFormService);
  form = this.formService.form;

  // Apis service
  private readonly service = inject(ForgotPasswordService);

  ngOnInit() {}

  OTP_CONF = INPUT_VERIFY_CODE_CONFIG;
  // UI
  public isInProcess: boolean = false;
  public displayErrorMessage: string = '';

  // Get otp form inout then path to form.
  public OTP: string = '';
  inputOTPChange(event: any) {
    this.OTP = event;
    this.form.patchValue({
      otp: this.OTP,
    });
  }

  onResend() {
    this.isInProcess = true;
    this.displayErrorMessage = '';
    this.form.disable();
    const { email, username } = this.form?.getRawValue();

    this.service
      .forgotPassword(username ?? '', email ?? '')
      .pipe(
        finalize(() => {
          this.isInProcess = false;
          this.form.enable();
        })
      )
      .subscribe({
        next: (value) => {
          this.form.markAsPristine();
          this.formService.goToInputOtp();
        },
        error: (err) => {
          this.displayErrorMessage = err.message;
        },
      });
  }

  next(): void {
    this.isInProcess = true;
    this.displayErrorMessage = '';
    this.form.disable();

    const { otp, username } = this.form?.getRawValue();

    this.service
      .confirmOTPCode(otp ?? '', username ?? '')
      .pipe(
        finalize(() => {
          this.isInProcess = false;
          this.form.enable();
        })
      )
      .subscribe({
        next: (value) => {
          this.formService.goToInputNewPassword();
        },
        error: (err) => {
          this.displayErrorMessage = err.message;
        },
      });
  }

  goBack(): void {
    this.formService.goToInputUsername();
  }
}
