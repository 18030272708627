<p class="text-left">
  Bạn đã xác minh OTP Thành công! Hãy điền mật khẩu mới vào ô bên dưới. <br />
  Yêu cầu mật khẩu: phải gồm chữ và số cùng với ký tự đặc biệt, có độ dài từ 8
  đến 20 ký tự
</p>

<form [formGroup]="form" *transloco="let translated">
  <div class="form-group">
    <!-- Check khoong trung tai khoan -->
    <app-input-password
      [label]="'Mật khẩu mới'"
      [class]="'form-control form-control-lg'"
      [disabled]="this.formService.inputNewPassword?.disabled ?? false"
      [setErrorMessage]="this.formService.showPasswordNotMatchMessage"
      (changePassword)="this.formService.inputNewPassword?.setValue($event)"
    >
    </app-input-password>

    <div class="mt-3"></div>

    <app-input-password
      [label]="'Xác nhận mật khẩu mới'"
      [class]="'form-control form-control-lg'"
      [disabled]="this.formService.inputConfirmNewPassword?.disabled ?? false"
      [setErrorMessage]="this.formService.showPasswordNotMatchMessage"
      (changePassword)="
        this.formService.inputConfirmNewPassword?.setValue($event)
      "
    >
    </app-input-password>
  </div>

  <button
    type="button"
    (click)="next()"
    [disabled]="!this.formService.canEnableSendPasswordButton"
    class="btn-block btn btn-lg btn-primary px-5 waves-effect waves-themed"
  >
    @if (this.waitingToVerify) {
    <span
      class="spinner-border spinner-border-sm mr-1"
      role="status"
      aria-hidden="true"
    ></span>
    }
    <span>
      {{
        translated(
          "auth.component.accountfirstLogin.formChangePassword.onChangePassword"
        )
      }}
    </span>
  </button>
  <div class="d-flex align-items-center justify-content-center">
    <a (click)="this.goBack()" class="btn btn-lg waves-effect waves-themed text-primary">
      <i class="fal fa-long-arrow-left mr-2"></i>
      <span>
        {{
          translated(
            "auth.component.accountfirstLogin.formChangePassword.goBack"
          )
        }}
      </span>
    </a>
  </div>
</form>
