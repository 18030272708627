<div class="d-flex align-items-center justify-content-center">
  <div
    class="col-md-9 card h-100 p-4 rounded overflow-hidden position-relative m-10"
  >
    <ng-content></ng-content>
   <!-- <footer class="mt-4 border-top m-0 mb-3" role="contentinfo"> -->
     <!-- <div
        class="d-flex align-items-center justify-content-between flex-1 text-muted mt-3"
      > -->
        <!-- <ul class="list-table">
          <li>
            <a
              href="https://qlcntt.hutech.edu.vn/"
              class="text-primary fw-500"
              title="Hướng dẫn khôi phục mật khẩu"
              target="_blank"
            >
              Hướng dẫn khôi phục mật khẩu
            </a>
          </li>
          <br />
        </ul> -->

        <!-- <div>
          <ul class="list-table">
            <li>
              <i class="fal fa-question-circle mr-1" aria-hidden="true"></i>
              <a
                href="https://qlcntt.hutech.edu.vn/ho-tro"
                class="text-secondary fw-700"
                title="Trung tâm Quản lý Công nghệ Thông tin"
                >Trợ giúp</a
              >
            </li>
          </ul>
        </div> -->
      <!-- </div> -->
    <!-- </footer> -->
  </div>
</div>
