<app-auth-layout *transloco="let translated">
  <div class="v2-page-heading text-center mb-3">
    <h1 class="h1 mb-0 mb-3 fw-700 fs-xxl text-left">
      <i class="fal fa-key mr-2"></i>
      Quên mật khẩu
    </h1>
    <hr />
  </div>

  
  <div class="login">
    <!--    Display error code when login by token failure-->
    <!--    Display error code when login by token failure-->

    <div class="row mb-4">
      <div class="col-12">
        @switch (this.formService.CURRENT_STEP) {
        @case(this.formService.CONTROLLER_STEP.INPUT_USER_NAME) {
        <app-input-username />
        } @case (this.formService.CONTROLLER_STEP.INPUT_EMAIL_ADDRESS) {
        <app-input-email />
        } @case (this.formService.CONTROLLER_STEP.INPUT_OTP) {
        <app-forgot-password-input-otp />
        } @case (this.formService.CONTROLLER_STEP.INPUT_NEW_PASSWORD) {
        <form-update-new-password />
        } @case (this.formService.CONTROLLER_STEP.NOTIFY) {
        <app-notify-after-send-email />
        } }
      </div>
    </div>

    <div class="oauth__cannot_login text-center">
      <hr />
      <span>Muốn khôi phục mật khẩu? </span>
      <a [href]="link" target="_blank" class="hover-underline"
        >Xem hướng dẫn tại đây</a
      >
    </div>
  </div>
</app-auth-layout>
