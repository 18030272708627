import { IForgotPassword } from '@features/auth/core/domain/forgot-password/forgot-password.interface';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CoreAuthConfigService } from '@features/auth/core-auth-config.service';
import { URI_APIs } from '@features/auth/const';
import { ErrorBase } from '@core/models/error.model';
import { ForgotPasswordErrorModel } from './model/verify-otp-code.error';

@Injectable()
export class ForgotPasswordService implements IForgotPassword {
  private readonly httpClient = inject(HttpClient);
  private readonly conf = inject(CoreAuthConfigService);

  forgotPassword(username: string, email: string): Observable<any> {
    const url = this.conf.buildURL(URI_APIs.FORGOT_PASSWORD);
    const requestBody = {
      username: username,
      email: email,
    };
    return this.httpClient.post(url, requestBody).pipe(
      catchError((err: ErrorBase) => {
        return throwError(() => new ForgotPasswordErrorModel().handle(err));
      })
    );
  }

  getHintEmail(username: string): Observable<any> {
    const url = this.conf.buildURL(URI_APIs.FORGOT_PASSWORD_HINT_EMAIL);
    const requestBody = {
      username: username,
    };
    return this.httpClient.post(url, requestBody).pipe(
      map((e: any) => e?.result ?? ''),
      catchError((err: ErrorBase) => {
        return throwError(() => new ForgotPasswordErrorModel().handle(err));
      })
    );
  }

  setNewPasswordByOTP(
    otp: string,
    username: string,
    newPassword: string
  ): Observable<any> {
    const requestBody = {
      otp: otp,
      new_password: newPassword,
      username: username,
    };
    return this.httpClient
      .post(
        this.conf.buildURL(URI_APIs.FORGOT_PASSWORD_SET_NEW_PASSWORD_BY_OTP),
        requestBody
      )
      .pipe(
        catchError((err: ErrorBase) => {
          return throwError(() => new ForgotPasswordErrorModel().handle(err));
        })
      );
  }

  confirmOTPCode(
    code: string,
    username: string,
    options?: any
  ): Observable<any> {
    const url = this.conf.buildURL(URI_APIs.FORGOT_PASSWORD_CONFIRM_OTP);
    const requestBody = {
      code: code,
      username: username,
    };
    return this.httpClient.post(url, requestBody).pipe(
      catchError((err: ErrorBase) => {
        return throwError(() => new ForgotPasswordErrorModel().handle(err));
      })
    );
  }
}
