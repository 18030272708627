import { StorageHelper } from '@features/auth/public-api';

/**
 * Kiem tra xem user co action nao nam trong danh sach action duoc phep su dung
 *
 * @param actionIds danh sach action cua he thong
 * @returns true neu co action cua user trong danh sach actionIds, nguoc lai tra ve false
 */
export function IsAllowToUseFeature(
  actionIds: Array<number | string>
): boolean {
  let userActionids = StorageHelper.getActivatedAction();

  if (!userActionids) {
    return false;
  }

  userActionids = userActionids as Array<number | string>;

  return actionIds.some((e) => userActionids.includes(e));
}

/**
 * Lay danh sach cac action cua user co quyen dung
 * @param actionIds danh sach action trong he thong
 * @returns danh sach action user co quyen dung
 */
export function GetListAllowActionIdByFeature(
  actionIds: Array<number | string>
): Array<number | string> {
  let userActionids = StorageHelper.getActivatedAction();

  if (!userActionids) {
    return [];
  }

  userActionids = userActionids as Array<number | string>;

  return actionIds.filter((e) => userActionids.includes(e));
}
