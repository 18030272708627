@if (this.otpFormService.currentStep !== -1) {

<app-auth-layout>
  <ng-container [ngSwitch]="this.otpFormService.currentStep">
    <!-- đổi pass -->
    <form-change-password
      *ngSwitchCase="this.otpFormService.CONTROLER_STEP.CHANGE_PASSWORD"
    >
    </form-change-password>

    <!-- đổi số điện thoại -->

    <form-email *ngSwitchCase="this.otpFormService.CONTROLER_STEP.VERIFY_EMAIL">
    </form-email>

    <app-finished-form
      *ngSwitchCase="this.otpFormService.CONTROLER_STEP.FINISH"
    />

    <app-verify-device
      *ngSwitchCase="this.otpFormService.CONTROLER_STEP.VERIFY_DEVICE"
    ></app-verify-device>

    <app-input-otp
      *ngSwitchCase="this.otpFormService.CONTROLER_STEP.VERIFY_OTP"
    ></app-input-otp>
    <!-- default case if none of the above matches -->
    <div *ngSwitchDefault>
      <!-- Handle default case if needed -->
    </div>
  </ng-container>
  <div class="oauth__cannot_login text-center">
    <hr />
    <span>Trợ giúp? </span>
    <a [href]="this.support_link" target="_blank" class="hover-underline cursor-pointer">Xem hướng dẫn tại đây</a>
  </div>
  
</app-auth-layout>
} @else {
<app-auth-layout *transloco="let translated">
  <div class="p-3">
    <div class="d-flex align-items-center">
      <strong
        >{{
          translated("auth.component.accountfirstLogin.loadingMessage")
        }}...</strong
      >
      <div
        class="spinner-border ml-auto"
        role="status"
        aria-hidden="true"
      ></div>
    </div>
  </div>

</app-auth-layout>
}
