import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { NgClass } from '@angular/common';

import { USER_INFO_ROUTING } from './const';

import { AccountInfoComponent } from './account-info/account-info.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ZaaBreadcumbComponent } from '@shared/components/zaa-breadcumb/zaa-breadcumb.component';

@Component({
  standalone: true,
  imports: [
    NgClass,
    NgClass,
    AccountInfoComponent,
    ChangePasswordComponent,
    ZaaBreadcumbComponent,
    RouterOutlet,
  ],
  selector: 'app-cai-dat-tai-khoan',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css'],
})
export class AccountPageComponent implements OnInit, OnDestroy {
  route = inject(ActivatedRoute);
  router = inject(Router);

  ngOnDestroy(): void {

  }
  ngOnInit(): void {
    const url: string = this.router.url;
    const segmentPath = url.split('/');
    this.activeTab = segmentPath[segmentPath.length - 1];
  }

  readonly ROUTING = USER_INFO_ROUTING;
  activeTab = USER_INFO_ROUTING.ACTIVE_TABS.DISPLAY_TAB_ACCOUNT;

  constructor() { }

  onChangeTab(activeTab: string) {
    this.activeTab = activeTab;
    this.router.navigate([this.ROUTING.PARENT_ROUTE, activeTab], {});
  }
}
