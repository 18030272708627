import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageAccountSettingRoutingModule } from './account.routing.module';

@NgModule({
  imports: [
    CommonModule,
    PageAccountSettingRoutingModule,
  ],
  providers: [],
  declarations: [
  ],
})
export class AccountSettingModule { }
