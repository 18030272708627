import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { CountdownButtonDirective } from '@features/auth/shared/directives/coutdown-and-disable.directive';
import { TranslocoModule } from '@ngneat/transloco';
import { IFormNagivation } from '../../forgot-password.interface';
import { ForgotPasswordFormService } from '../../forgot-password.form.service';

@Component({
  standalone: true,
  imports: [CommonModule, CountdownButtonDirective, TranslocoModule],
  selector: 'app-notify-after-send-email',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.css'],
})
export class NotifyAfterSendEmailComponent implements OnInit, IFormNagivation {
  // Form service
  formService = inject(ForgotPasswordFormService);
  form = this.formService.form;

  goBack(): void {}
  next(): void {
    this.formService.goToLogin();
  }

  ngOnInit(): void {}
}
