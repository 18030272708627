import { Component, OnDestroy, OnInit, inject, isDevMode } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FinishedFormComponent,
  ChangeDefaultPasswordComponent,
} from './components/public-api';
import { Subject } from 'rxjs';
import { VerifyEmailComponent } from '@features/auth/implements/account-first-login/components/verify-email/form-email.component';
import { AuthLayoutComponent } from '@features/auth/shared/components/auth-layout/auth-layout.component';
import { VerifyDeviceComponent } from './components/verify-device/verify-device.component';
import { TranslocoModule } from '@ngneat/transloco';
import { AccountFirstLoginFormOTPService } from './account-first-login-otp.service';
import { InputOtpComponent } from './components/input-otp/input-otp.component';
import { LINK_SUPPORT } from '@features/auth/const';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FinishedFormComponent,
    VerifyEmailComponent,
    ChangeDefaultPasswordComponent,
    AuthLayoutComponent,
    VerifyDeviceComponent,
    TranslocoModule,
    InputOtpComponent,
  ],
  providers: [],
  selector: 'app-form-nhap',
  templateUrl: './require-update-info.component.html',
  styleUrls: ['./require-update-info.component.scss'],
})
export class FormCapNhatThongTin implements OnInit, OnDestroy {
  readonly otpFormService = inject(AccountFirstLoginFormOTPService);

  destroy$$ = new Subject<void>();

  support_link = LINK_SUPPORT.VERIFY_ACCOUNT_AT_LOGIN;

  ngOnInit() {
    this.otpFormService.activeFormByKey();
  }

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }
}
