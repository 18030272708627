import { Injectable, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ErrorBase } from '@core/models/error.model';
import { EMAIL_PATTERN } from '@features/auth/shared/helpers/email-pattern';
import { PasswordStrengthHelper } from '@features/auth/shared/helpers/password-strength-validator.helper';

@Injectable()
export class ForgotPasswordFormService {
  public router = inject(Router);

  // An hien cac form

  CONTROLLER_STEP = {
    INPUT_USER_NAME: 0,
    INPUT_EMAIL_ADDRESS: 1,
    INPUT_OTP: 2,
    INPUT_NEW_PASSWORD: 3,
    NOTIFY: 4,
  };

  CURRENT_STEP = this.CONTROLLER_STEP.INPUT_USER_NAME;

  // hien thi email abc***gh@gmail.com
  emailHintDisplay: string = '';

  readonly form = inject(FormBuilder).group(
    {
      username: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
      otp: ['', [Validators.required]],
      new_password: ['', [Validators.required]],
      confirm_new_password: ['', [Validators.required]],
    },
    {
      validators: [
        this.passwordsMatchValidator,
        PasswordStrengthHelper.createPasswordStrengthValidator,
      ],
    }
  );
  // Kiểm tra pass và new pass bằng nhau
  private passwordsMatchValidator(
    group: FormGroup | any
  ): { [key: string]: boolean } | null {
    const password = group?.get('new_password')?.value;
    const confirmPassword = group?.get('confirm_new_password')?.value;
    return password === confirmPassword ? null : { passwordsNotMatch: true };
  }

  get canEnableSendPasswordButton() {
    return (
      !this.form.invalid &&
      !this.form.hasError('passwordsNotMatch') &&
      !this.form.hasError('mustMoreThen8Characters') &&
      !this.form.hasError('containNumberAndCharacters')
    );
  }

  get inputNewPassword() {
    return this.form.get('new_password');
  }

  get inputConfirmNewPassword() {
    return this.form.get('confirm_new_password');
  }

  get showPasswordNotMatchMessage() {
    return this.inputConfirmNewPassword?.value &&
      this.form.hasError('passwordsNotMatch')
      ? 'Mật khẩu mới và xác nhận mật khẩu không khớp'
      : '';
  }

  // Navigtation
  goToInputUsername() {
    this.CURRENT_STEP = this.CONTROLLER_STEP.INPUT_USER_NAME;
  }

  goToInputEmail() {
    this.CURRENT_STEP = this.CONTROLLER_STEP.INPUT_EMAIL_ADDRESS;
  }

  goToInputOtp() {
    this.CURRENT_STEP = this.CONTROLLER_STEP.INPUT_OTP;
  }

  goToInputNewPassword() {
    this.CURRENT_STEP = this.CONTROLLER_STEP.INPUT_NEW_PASSWORD;
  }

  goToNotify() {
    this.CURRENT_STEP = this.CONTROLLER_STEP.NOTIFY;
  }

  goToLogin() {
    this.router.navigate(['login']);
  }
}
