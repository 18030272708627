import { Component, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { finalize } from 'rxjs';
import { InputPasswordComponent } from '@features/auth/shared/components/input-password/input-password.component';
import { ToastrService } from '@shared/components/lib-ngx/ngx-toastr/toastr/toastr.service';
import { TranslocoModule } from '@ngneat/transloco';
import { ForgotPasswordService } from '@features/auth/core/domain/forgot-password/forgot-password.service';
import { ForgotPasswordFormService } from '../../forgot-password.form.service';
import { IFormNagivation } from '../../forgot-password.interface';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputPasswordComponent,
    TranslocoModule,
  ],
  selector: 'form-update-new-password',
  templateUrl: './form-change-password.component.html',
  styleUrls: ['./form-change-password.component.scss'],
})
export class ChangeDefaultPasswordComponent implements OnInit, IFormNagivation {
  private readonly service = inject(ForgotPasswordService);
  // Form service
  formService = inject(ForgotPasswordFormService);
  form = this.formService.form;

  showPassword: boolean = false;
  oldPasswordNotMatchError: boolean = false;
  waitingToVerify: boolean = false;

  public displayErrorByCode: number = -1;

  ngOnInit() {}

  next(): void {
    if (
      this.form.get('new_password')?.invalid ||
      this.form.get('otp')?.invalid
    ) {
      return;
    }

    this.waitingToVerify = true;
    this.form.disable();

    const { new_password, otp, username } = this.form.getRawValue();

    this.service
      .setNewPasswordByOTP(otp ?? '', username ?? '', new_password ?? '')
      .pipe(
        finalize(() => {
          this.waitingToVerify = false;
          this.form.enable();
        })
      )
      .subscribe({
        next: (value) => {
          if (value) {
            this.formService.goToNotify();
          }
        },
        error: (err) => {
          this.displayErrorByCode = err.message;
        },
      });
  }
  goBack(): void {
    this.formService.goToLogin();
  }
}
