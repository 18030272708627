import {IRequireUpdateInfo} from "@features/auth/core/domain/require-update-info/require-update-info.interface";
import {inject, Injectable} from "@angular/core";
import {map, Observable, of, tap} from "rxjs";
import {
  IRequirementUpdateInfoResponse
} from "@features/auth/implements/require-update-info/interfaces/requirement.interface";
import {RequirementToUseAppModel} from "@features/auth/core/domain/require-update-info/requirement-to-use-app.model";
import {StorageHelper} from "@features/auth/shared/helpers/storage.helper";
import {
  IRequirementModel
} from "@features/auth/implements/require-update-info/interfaces/requirement-viewmode.interface";
import {HttpClient} from "@angular/common/http";
import {CoreAuthConfigService} from "@features/auth/core-auth-config.service";
import {URI_APIs} from "@features/auth/const";

@Injectable()
export class RequireUpdateInfoService implements IRequireUpdateInfo {
  private readonly httpClient = inject(HttpClient);
  private readonly conf = inject(CoreAuthConfigService);

  getRequirement(): Observable<any> {
    const requirement = this.getRequirementFromStorage();

    if (requirement && requirement?.length !== 0) {
      return of(requirement);
    }

    const url = this.conf.buildURL(URI_APIs.REQUIRE_UPDATE_INFO_GET)
    return this.httpClient
      .get<IRequirementUpdateInfoResponse>(url)
      .pipe(
        map((response) =>
          new RequirementToUseAppModel().mapArray(response.result),
        ),
        tap((result) => {
          StorageHelper.storeRequireUpdateInfoToUseApp(result);
        }),
      );
  }

  getRequirementFromStorage(): Array<IRequirementModel> {
    return StorageHelper.getRequireUpdateInfoToUseApp();
  }

  clearRequirement() {
    StorageHelper.storeRequireUpdateInfoToUseApp([]);
  }

}
