import { AccountSettingService } from '@features/auth/core/domain/account/account-setting.service';
import { AuthService } from '@features/auth/core/domain/login/auth-version-2.service';
import { ForgotPasswordService } from '@features/auth/core/domain/forgot-password/forgot-password.service';
import { RequireUpdateInfoService } from '@features/auth/core/domain/require-update-info/require-update-info.service';
import { VerifyAccountFirstLoginService } from '@features/auth/core/domain/account-first-login/verify-account-first-login.service';
import { RegisterService } from '@features/auth/core/domain/register/register.service';
import { VerifyOTPCodeService } from './account-first-login/verify-code-otp.service';
import { ProfileService } from './profile/profile.service';
import { AuthVersion1Service } from './login/auth-version-1.service';
import { LogoutService } from './login/logout.service';
import { UserInfoStorageService } from './login/user-info-storage.service';
import { AuthEventEmiter } from './events/auth.event';

export const SERVICE_IMPLEMENTS = [
  AccountSettingService,
  AuthService,
  AuthVersion1Service,
  LogoutService,
  UserInfoStorageService,
  ForgotPasswordService,
  RequireUpdateInfoService,
  VerifyAccountFirstLoginService,
  RegisterService,
  VerifyOTPCodeService,
  ProfileService,
  AuthEventEmiter,
];
