import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgOtpInputComponent } from '@features/auth/shared/components/ng-otp-input/ng-otp-input.component';
import { Config } from '@features/auth/shared/components/ng-otp-input/config';
import { CountdownButtonDirective } from '@features/auth/shared/directives/coutdown-and-disable.directive';
import { finalize, Subject } from 'rxjs';
import { VerifyAccountFirstLoginService } from '@features/auth/core/domain/account-first-login/verify-account-first-login.service';
import { VerifyOTPCodeService } from '@features/auth/core/domain/account-first-login/verify-code-otp.service';
import { AuthLayoutComponent } from '@features/auth/shared/components/auth-layout/auth-layout.component';
import { TranslocoModule } from '@ngneat/transloco';
import { AccountFirstLoginFormOTPService } from '../../account-first-login-otp.service';
import { INPUT_VERIFY_CODE_CONFIG } from './config';

/**
 * Component verify email dung chung cho cac nghiep vu verify email (tru nghiep vu quen mat khau.)
 */
@Component({
  standalone: true,
  imports: [
    CommonModule,
    NgOtpInputComponent,
    CountdownButtonDirective,
    AuthLayoutComponent,
    TranslocoModule,
  ],
  selector: 'app-input-otp',
  templateUrl: './input-otp.component.html',
  styleUrls: ['./input-otp.component.css'],
})
export class InputOtpComponent implements OnInit, OnDestroy {
  constructor() {}
  public readonly service = inject(VerifyAccountFirstLoginService);

  public readonly verifyOTPService = inject(VerifyOTPCodeService);
  otpFormService = inject(AccountFirstLoginFormOTPService);

  public INPUT_VERIFY_CODE_CONFIG: Config = INPUT_VERIFY_CODE_CONFIG;

  public OTP: string = '';
  // UI
  public isInProcess: boolean = false;

  public displayErrorMessage: string = '';

  destroy$$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  ngOnInit() {
    if (this.otpFormService.isEmptyEmailAddress()) {
      this.otpFormService.nagivateToVerifyInfo();
    }
  }

  inputOTPChange(event: any) {
    this.OTP = event;
  }

  onSend() {
    this.displayErrorMessage = '';
    this.isInProcess = true;

    this.verifyOTPService
      .confirmOTPCode(this.OTP)
      .pipe(
        finalize(() => {
          this.isInProcess = false;
        })
      )
      .subscribe({
        next: (response) => {
          this.goBack();
        },
        error: (err) => {
          console.error(err);
          this.displayErrorMessage = err.message;
        },
      });
  }

  onResend() {
    this.displayErrorMessage = '';
    switch (this.otpFormService.switchViewVerifyCode) {
      case this.otpFormService.typeVerify.EMAIL:
        this.service
          .verifyEmailAddress(this.otpFormService.getVerifyEmailAddress, {})
          .subscribe({
            next: (value) => {},
            error: (err) => {
              console.error(err);
              this.displayErrorMessage = err.message;
            },
          });
        break;
      case this.otpFormService.typeVerify.DEVICE:
        this.service.sendVerifyDeviceCode().subscribe({
          next: (value) => {},
          error: (err) => {
            console.error(err);
            this.displayErrorMessage = err.message;
          },
        });
        break;
      default:
        break;
    }
  }

  goBack() {
    this.otpFormService.nagivateToVerifyInfo();
    this.otpFormService.activeFormByKey();
  }
}
