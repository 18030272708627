import _ from 'lodash';
import {
  IRequireAuthInfoResponse,
  VerifyInfo,
} from '../dto/require-auth.response';


export interface IVerifyInfo {
  action_key: string;
  status: string;
  type: string;
}
export class VerifyAccountFirstLogin {
  TYPE_REQUIRE_NAME = 'require';
  TYPE_WARNING_NAME = 'warning';

  public static REQUIRE_TO_UPDATE_PASSWORD = 'ChangePassword';
  public static REQUIRE_TO_UPDATE_EMAIL_KEY = 'VerifyEmail';
  public static REQUIRE_TO_UPDATE_PHONE_KEY = 'ChangePhone'; // sms
  public static REQUIRE_TO_UPDATE_DEVICE_KEY = 'VerifyDevice';

  private require!: {
    verify_info: Array<IVerifyInfo>;
    verify_device: boolean;
  };

  // Uu tien 1: cao nhat , 4 thap nhat ,,,
  public PRIORITY_REQUIREMENTS = [
    {
      key: VerifyAccountFirstLogin.REQUIRE_TO_UPDATE_PASSWORD,
      priority: 1,
    },
    {
      key: VerifyAccountFirstLogin.REQUIRE_TO_UPDATE_EMAIL_KEY,
      priority: 2,
    },
    {
      key: VerifyAccountFirstLogin.REQUIRE_TO_UPDATE_DEVICE_KEY,
      priority: 4,
    },
    {
      key: VerifyAccountFirstLogin.REQUIRE_TO_UPDATE_PHONE_KEY,
      priority: 3,
    },
  ];

  setRequire(req: IRequireAuthInfoResponse) {
    this.require = req;
  }

  // Get first require contain priority.
  getFirstRequire() {

    const prioritySorted = this.PRIORITY_REQUIREMENTS.sort((a, b) =>
      a.priority > b.priority ? 1 : -1
    );

    for (let index = 0; index < prioritySorted.length; index++) {
      const require = this.require.verify_info.find(
        (value) =>
          value.status == 'false' &&
          value.type == this.TYPE_REQUIRE_NAME &&
          value.action_key == prioritySorted[index].key
      );
      if (require) {
        return require;
      }
    }

    // Case verify device;
    if (!_.isNil(this.require.verify_device) && !this.require.verify_device) {
      const req: IVerifyInfo = {
        action_key: VerifyAccountFirstLogin.REQUIRE_TO_UPDATE_DEVICE_KEY,
        status: 'false',
        type: this.TYPE_REQUIRE_NAME,
      };
      return req;
    }
    return undefined;
  }
}
