import { Component, OnInit, inject } from '@angular/core';
import { take, finalize } from 'rxjs';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { InputUserNameHelper } from '@features/auth/shared/helpers/display-email-error.helper';
import { ForgotPasswordService } from '@features/auth/core/domain/forgot-password/forgot-password.service';
import { TranslocoModule } from '@ngneat/transloco';
import { ForgotPasswordFormService } from '../../forgot-password.form.service';
import { IFormNagivation } from '../../forgot-password.interface';
import { ZaaAutofocusDirective } from '@shared/directives/zaa-autofocus/zaa-autofocus.directive';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslocoModule,
    ZaaAutofocusDirective,
  ],
  selector: 'app-input-email',
  templateUrl: './input-email.component.html',
  styleUrls: ['./input-email.component.css'],
})
export class InputEmailComponent implements IFormNagivation {
  // Form service
  formService = inject(ForgotPasswordFormService);
  form = this.formService.form;
  // Api service
  private readonly service = inject(ForgotPasswordService);


  // UI variable
  // error message when viery email at client
  displayErrorMessage = '';

  // Error code when show error from server

  public showSpinner: boolean = false;

  goBack(): void {
    this.formService.goToInputUsername();
  }

  next(): void {
    this.displayErrorMessage = '';
    const emailForm = this.form.get('email');

    // Verify email
    if (emailForm?.hasError('pattern')) {
      this.displayErrorMessage =
        InputUserNameHelper.displayError('wrongFormat');
      return;
    }

    if (emailForm?.hasError('required')) {
      this.displayErrorMessage = InputUserNameHelper.displayError('empty');
      return;
    }

    // Call api
    this.showSpinner = true;
    this.form.disable();

    const { email, username } = this.form?.getRawValue();
    this.service
      .forgotPassword(username ?? '', email ?? '')
      .pipe(
        take(1),
        finalize(() => {
          this.showSpinner = false;
          this.form.enable();
        })
      )
      .subscribe({
        next: (value) => {
          this.form.markAsPristine();
          this.formService.goToInputOtp();
        },
        error: (err) => {
          this.displayErrorMessage = err.message;
        },
      });
  }
}
